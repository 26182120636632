export default {
  "help": {
    "check_docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check our docs"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need help ?"])},
    "help_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need help? Click here"])}
  },
  "navbar": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search  a student...."])},
    "no_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found"])},
    "add_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add this student"])},
    "select_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select  classroom"])}
  },
  "sidebar": {
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access control"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classrooms"])},
    "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurations"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Params"])},
    "other-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other payments"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schooling"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "transfert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfers"])},
    "operations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FINANCIAL OPERATIONS"])},
    "scolarité": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuition"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need help ?"])},
    "profil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaging"])},
    "sponsoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsoring"])}
  },
  "button": {
    "See-more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See more"])},
    "see_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "add_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New installment"])},
    "select_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the group"])},
    "modify_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify the group"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import (excel file)"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
    "send_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send invoice"])},
    "add_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New student"])},
    "excel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel file"])},
    "add_students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])}
  },
  "dashboard": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome!"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipts"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
    "share_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share the link"])},
    "copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy the link"])},
    "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to share the link on Facebook"])},
    "x": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to share the link on X"])},
    "linkedin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to share the link on LinkedIn"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to copy the link"])},
    "success_copy_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link copied to the clipboard !"])},
    "title-card-payemnt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending receipts"])},
    "title-no-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payment"])},
    "text-no-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipts sent by parents will appear here"])},
    "parents_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of parents registered on Eska"])},
    "total-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total "])},
    "pending-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "stats": {
      "money": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total "])},
        "title_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total payment"])}
      },
      "users": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue"])},
        "title_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment  overdue"])}
      },
      "clients": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending "])},
        "title_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment pending"])}
      },
      "sales": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manually Confirmed"])},
        "title_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manually Confirmed Payments"])}
      }
    }
  },
  "classroom": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classrooms"])},
    "classroom_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classroom list"])},
    "code_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Pending"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending amount"])},
    "code_overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue"])},
    "add_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a classroom"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "filter_option1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classroom list"])},
    "filter_option2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classroom successfully added "])},
    "delete_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classroom delete successfully"])},
    "filter_option3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue"])},
    "add_class_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create classrooms and define the expected costs for each one. Parents will receive alerts on the Eska application and will be able to pay"])},
    "button_new_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New classroom"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payment applied. Click on a classroom to set up payments and add students"])},
    "alert_list_classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No classroom created"])},
    "p_list_classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to create classes, enrol pupils in them, generate payments for each classroom and allow parents to receive alerts via the mobile application"])},
    "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this classroom?"])},
    "modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleting this class will result in the loss of certain data associated with this classroom but this operation is not retrospective, payments already sent or settled will not be impacted."])}
  },
  "classdetails": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "button_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert the parents who subscribed to this service."])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
    "no_group_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payment group created"])},
    "no_group_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please click below to create payment groups."])},
    "select_groupe_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a group for this classroom to make its installements available for payment to the students."])},
    "no_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No instalment configured."])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalment"])},
    "no_other_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No other payment created."])},
    "modal_success_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now add students to the classroom and change the status of their payments."])},
    "modal_success_groupe_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The group was successfully applied to the classroom."])},
    "modal_apply_groupe_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to apply this group to this classroom."])},
    "payment_no_approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments approved manually."])},
    "modal_noinstallment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible to apply group to classroom."])},
    "modal_noinstallment_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can't apply this group to a classroom because it doesn't contain a payment range."])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipts"])},
    "receipt_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipts sent by parents will appear here."])},
    "other_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other payment"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
    "photo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to view the details of this student."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reg. N⁰ "])},
    "PendingPayment": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending payment"])}
    },
    "PaymentOverdue": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue payment"])}
    },
    "class_alert_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot add students"])},
    "class_alert_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please apply a group to the classroom in order to be able to add students"])},
    "school_monitoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School follow-up!"])}
  },
  "modal_pending": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply a status to this installment for selected students ?"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment status of the chosen installment, for all selected students, will be defined by one of the choices below. Please check the appropriate one and confirm."])},
    "status_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending payment"])},
    "status_pending_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This installment will be marked as unpaid for the selected students, payment requests will be sent to parents on Eska mobile app and they will receive a notification."])},
    "status_settled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment settled"])},
    "status_settled_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This option indicates that you have already received the money from the selected students for this installment, the payment will appear as settled on your interface, as well as on Eska app. Check carefully before confirming."])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalment"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due date"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select an option before confirming. "])}
  },
  "modal_pending_success": {
    "notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification sent on Eska"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
    "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due date"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installment"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status modified successfully"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click the Send button below to inform parents of affected students that a payment is pending."])}
  },
  "modal_settled_success": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status modified successfully"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If no receipt is received, a message will be sent to the various managers to inform them of this modification."])}
  },
  "studentlist": {
    "student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students"])},
    "no_student_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No students added"])},
    "no_student_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add students to the classroom in order to be able to apply the payment bands from the configuration applied to the class."])},
    "table_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select students to change their payment status."])},
    "table_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "table_matricul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration number"])},
    "no_statut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No status"])},
    "table_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "schooling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuition"])},
    "overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue"])},
    "table_current_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current"])},
    "table_statut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "settled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settled"])},
    "select_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select status"])},
    "select_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the installment"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installment"])},
    "title_modal_no_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No slice selected."])},
    "text_modal_no_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close the modal and select a slice from the list of slices before applying."])},
    "title_modal_apply_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to apply this band to the classroom?"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due date"])},
    "modal_title_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slice successfully applied to students."])},
    "modal_text_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now change the status of students by selecting the appropriate status."])},
    "title_modal_no_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No status selected."])},
    "text_modal_no_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close the modal and select a status from the status list before applying."])},
    "title_modal_status_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to change the status of this payment?"])},
    "text_modal_status_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et quoniam apud eos ut in capite mundi morborum acerbitates celsius dominantur, ad quos vel sedandos omnis professio medendi torpescit, excogitatum est adminiculum sospitale nequi amicum perferentem similia videat, additumque est cautionibus paucis remedium aliud satis validum, ut famulos percontatum missos quem ad modum valeant noti."])},
    "text_modal_settled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Without invoice confirmation, a message will be sent to the various managers to inform them of this change."])},
    "title_modal_status_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status has been modified successfully"])},
    "text_modal_status_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on the button sent below to inform the parents of the pupils concerned that an invoice is pending."])},
    "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo"])},
    "other_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other payment"])},
    "parents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "no_statut_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No status applied"])},
    "pending_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending payments"])},
    "settled_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settled payments"])},
    "overdue_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue payments"])},
    "select_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to edit the status of this slice"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerts"])},
    "note_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grades available"])},
    "sequence_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Dates of Exams sessions"])},
    "course_suspension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temporary school break"])},
    "convocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disciplinary board meeting summons"])},
    "absence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period of Absence"])},
    "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow-up report"])},
    "notif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerts"])},
    "alert_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed to alerts"])}
  },
  "payment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class group"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciality"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total amount"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add  instalement for this payment group"])},
    "total_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installments"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group successfully deleted"])},
    "button_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "no_payment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No configuration has been created"])},
    "no_payment_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment configuration offers you the possibility of dividing your payments into several instalments, according to each class."])},
    "modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this group?"])},
    "modal_delete_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action is not retroactive, payments already sent or settled will not be impacted. You will need to redefine the applicable fees for the classrooms to which the group was applied. "])}
  },
  "newpayment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create payment configuration"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A group is a set of classes with the same tuition fees. <br/> Eg: from 1,from 5,form 3. Choose a level and a speciality, then save."])},
    "speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciality"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "title_icon_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create installment"])},
    "title_icon_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the group"])},
    "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group successfully created"])},
    "modal_button_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create installments   "])},
    "modal_button_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add  group"])},
    "modal_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now create installments for this group, or create another group."])},
    "no_payment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payment configuration created"])},
    "no_payment_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment configuration offers you the possibility of dividing your payments into several instalments, according to each class"])}
  },
  "updatepayment": {
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify group"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "no_speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No speciality"])},
    "speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciality"])},
    "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to modify this group?"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group modified successfully"])}
  },
  "other_payment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other payments"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due date"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single"])},
    "period_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View the list of periods"])},
    "recurring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurring"])},
    "title_icon_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit payment"])},
    "title_icon_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete payment"])},
    "title_no_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No other payment"])},
    "modal_add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment type"])},
    "modal_add_card1_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment made in a single installment."])},
    "modal_add_card2_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment that repeats regularly, on a periodic basis."])},
    "message_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment successfully deleted."])}
  },
  "single_payment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single Payment"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a Single Payment"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "page_modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify the payment"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due Date"])},
    "message_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment created successfully"])},
    "message_modify_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment modified successfully"])},
    "message_modify_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error modifying the payment"])},
    "modal_modify_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to modify this payment?"])},
    "modal_modify_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This operation is not retroactive; payments that have already been sent or settled will not be affected. You will need to redefine the applicable fees for the classes to which the payment is linked."])}
  },
  "recurring_payment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurring Payment"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a Recurring Payment"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designation"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
    "error_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount is required"])},
    "error_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month is required"])},
    "error_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day is required"])},
    "add_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Period"])},
    "delete_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Period"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment created successfully"])},
    "period_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this section, you can define the different applicable payment periods, such as monthly, quarterly or annual. This will help us better understand your payment frequency needs."])}
  },
  "installment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["payment instalments"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
    "title_payement_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now allocate instalments to each pupil in this classroom"])},
    "due_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due date"])},
    "modal_modify_head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify the installment"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "amount_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter  amount"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The instalment configuration is complete, you can now view your payments"])},
    "no_installment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payment instalments"])},
    "no_installment_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment instalments correspond to defined periods for the payment of tuition fees"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalment"])},
    "title_icon_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify the instalment"])},
    "title_icon_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the instalment"])},
    "modal_modify_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to modify this installment?"])},
    "modal_modify_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action is not retroactive, payments already sent or settled will not be impacted. You will have to redefine the applicable fees for the classrooms to which the group was linked."])},
    "add_installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add installment"])},
    "modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this slice?"])},
    "modal_delete_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This operation is not retroactive. Payments already sent or paid will not be affected. You will need to redefine the applicable fees for the following classes: "])},
    "message_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installment deleted successfully"])},
    "message_modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installment modified successfully"])},
    "newinstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New instalment"])}
  },
  "params": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use these impressive forms to log in or create a new account in your project for free."])},
    "params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration parameters"])},
    "training_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type formation "])},
    "education_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education system"])},
    "education_grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Educational level"])},
    "country_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country code"])},
    "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
    "neighbourhood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neighbourhood"])},
    "school_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name school"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations incorrects ?"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact the platform "])}
  },
  "paymentpending": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Awaiting confirmation"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "matricul": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration number"])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipts"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "title_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment details pending"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["installment"])},
    "title_modal_reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject payment"])},
    "p_modal_reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicate the reason for rejecting the payment"])},
    "modal_reject_motif": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter reason...."])},
    "title_modal_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to confirm this payment?"])},
    "text_modal_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vide, quantum, inquam, fallare, Torquate. oratio me istius philosophi non offendit; nam et complectitur verbis, quod vult, et dicit plane, quod intellegam; et tamen ego a philosopho, si afferat eloquentiam, non asperner, si non habeat, non admodum flagitem. re mihi non aeque"])}
  },
  "accounts": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of administrators"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first name"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "title_icon_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send informations"])},
    "M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "F": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "autorisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisation"])},
    "no_admint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No administrator added"])},
    "lock_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sure you want to revoke this user access?"])},
    "lock_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user will no nore be able to access this admin panel."])},
    "unlock_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sure you want to grant access to this user?"])},
    "unlock_modal_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user will be able to access this admin panel again."])},
    "title_icon_lock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block user "])},
    "title_icon_unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlock user"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
    "lock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorised"])},
    "unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not authorised"])},
    "no_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page reserved for admins"])},
    "message_lock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User locked successfully"])},
    "message_unlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User unlocked successfully"])}
  },
  "newaccounts": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New administrator"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an administrator"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number (used on Eska)"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone (695623235)"])},
    "no_eska": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User is not on Eska."])},
    "message_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User added successfully."])}
  },
  "logout": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sure you want to logout?"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User logged out successfully."])}
  },
  "students_detail": {
    "modal_no_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible to delete the student"])},
    "modal_no_delete_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot delete this student because they have already made payments."])},
    "modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the student"])},
    "modal_delete_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This operation cannot be reverted. His/Her payment history will be kept."])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration number"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last name"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first name"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents' telephone numbers"])},
    "phone_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Separate numbers with commas"])},
    "place_birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place of birth"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])},
    "sexe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "F": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
    "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalment"])},
    "settled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settled"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "other_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other payment"])}
  },
  "new_student": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New student"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new student"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration number"])},
    "number_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the number or generate it automatically"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "gendered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "F": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents phone  numbers"])},
    "phone_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After adding phone number, please press the space bar on the keyboard to save"])},
    "place_birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place of birth"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])},
    "image_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click in the image frame to add an image"])},
    "placeholder_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the phone number"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Student successfully added "])},
    "new_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New student"])},
    "old_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old students"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])}
  },
  "import_student": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import students from a csv file"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the following csv file template to fill in the students in order to add them to the platform (You can fill them in an excel file by following this template and saving it at the end in csv format)."])},
    "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV file"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to view "])},
    "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate"])},
    "generate_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If student does not have a registration number, press Generate to create a new."])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of students"])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the number of students to generate"])},
    "old_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Former students"])},
    "new_student": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New students"])},
    "error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the number of registration numbers to generate."])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The file has been successfully generated."])},
    "title_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate the registration number"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to upload the file"])},
    "title_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate the registration number?"])},
    "text_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A template file will be pre-filled with generated registration number."])},
    "btn1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate and download"])},
    "btn2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, thank you!"])},
    "text_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click the following button to download the file"])},
    "title_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to download."])},
    "top_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to fill out a file according to the following template:"])},
    "btn_model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the template"])}
  },
  "new_class": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New classroom"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a classroom "])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
    "speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciality"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class number"])},
    "student_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of students"])},
    "no_speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No speciality"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classroom added successfully"])},
    "impossible_add_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible to create classroom because it already exists"])}
  },
  "update_class": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify the classroom"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classroom"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a classroom sale"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
    "speciality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciality"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classroom number"])},
    "student_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of students"])},
    "title_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to modify this classroom?"])},
    "text_modal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paphius quin etiam et Cornelius senatores, ambo venenorum artibus pravis se polluisse confessi, eodem pronuntiante Maximino sunt"])},
    "message_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classroom successfully modified"])}
  },
  "receipt_list": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipts sent"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registration number"])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipts"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grade"])}
  },
  "payment_pending": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment pending"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registration number"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "no_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No current payment."])}
  },
  "no_bill_payment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments approved without invoice confirmation"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registration number"])},
    "no_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payment approved."])}
  },
  "duedate_payment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overdue payments"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registration number"])},
    "no_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payment."])}
  },
  "list_class_payment": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved payments grouped by classroom"])}
  },
  "eska": {
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General statistics"])}
  },
  "message": {
    "notinternet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are offline."])},
    "send_invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice send"])},
    "requiredfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in all fields."])},
    "user404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, the requested page could not be found."])}
  },
  "title": {
    "page_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments Pending"])},
    "page_noreceipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments approved without receipts"])},
    "page_overdue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments overdue"])}
  },
  "transaction": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction History"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "see_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See All"])},
    "successful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful"])},
    "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
    "operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operator"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "id_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User ID"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "all_periods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All transaction"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To day"])},
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yesterday"])},
    "last_28_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 28 day"])},
    "since_the_1st": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since the first day"])},
    "last_7_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 7 days"])},
    "all_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All status"])},
    "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refused"])},
    "no_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No transaction"])},
    "no_transaction_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will be able to track payments here in real time"])},
    "all_operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All operators"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "filter_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other.... "])},
    "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter transactions"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])}
  },
  "transfer": {
    "info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Transfer"])},
    "info_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your collection will be transferred to you as soon as the amount reaches "])},
    "info_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer fees: 4,770 including tax"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "no_transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No transfer"])},
    "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recipient"])},
    "receiver_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiver account"])},
    "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank"])},
    "account_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account number"])},
    "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modify"])},
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfers"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yesterday"])},
    "last_28_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 28 day"])},
    "since_the_seven_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Last 7 days"])},
    "all_periods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All periods"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period"])},
    "reclamation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réclamation"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "executed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Executed"])},
    "ready": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready"])},
    "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing"])},
    "modal_bank_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank information required"])},
    "modal_bank_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To receive your transfers, please complete your banking information."])},
    "modal_bank_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete my bank information"])}
  },
  "configuration": {
    "transfert_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The minimum transfer amount is"])},
    "bearcosts_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bear the costs"])},
    "bearcosts_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction fees will be deducted from you rather than from the parents"])},
    "splitpayment_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment in small instalments"])},
    "splitpayment_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parents will be able to pay at their own pace as long as they stay on schedule. You will have details of each payment, as well as the summary."])},
    "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
    "bank_info_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank information"])},
    "admin_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This page is strictly reserved for administrators."])}
  },
  "profil": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Information"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User name"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])}
  },
  "todolist": {
    "tuition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuition"])},
    "other_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other payment"])},
    "instalment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instalment"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
    "no_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No payment"])},
    "no_other_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No other payments at this time."])}
  },
  "sponsoring": {
    "title_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsored school"])},
    "body_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Your establishment is subject to the ESKA sponsorship programme. You benefit from all the premium advantages."])},
    "bottom_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active program"])}
  },
  "date_sequence": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exams sessions schedule - Step"])},
    "label1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the sequence"])},
    "error1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a sequence."])},
    "label2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the relevant levels"])},
    "error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select at least one level."])},
    "label3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the subjects for which grades are available"])},
    "error3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select at least one subject."])},
    "label4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date"])},
    "label5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date"])},
    "error4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the start date."])},
    "error5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the end date."])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information sent successfully."])}
  },
  "temporary_suspension": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temporary Course Suspension."])},
    "label1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the relevant levels."])},
    "error1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select at least one level."])},
    "label2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date."])},
    "error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the start date."])},
    "label3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date."])},
    "error3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select the end date."])},
    "label4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason."])},
    "error4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a reason."])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The start date must be earlier than the end date."])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspension sent successfully."])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ex: Strike, holidays..."])}
  },
  "grade_available": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available Grades - Step"])},
    "label1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the sequence."])},
    "error1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a sequence."])},
    "label2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the subjects for which grades are available."])},
    "error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select at least one subject."])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Availability of notes sent successfully."])},
    "seq1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exams session 1"])},
    "seq2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exams session 2"])},
    "seq3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exams session 3"])},
    "seq4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exams session 4"])},
    "seq5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exams session 5"])},
    "seq6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exams session 6"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At the end of this operation, parents will be informed that the copies have been corrected by the teacher and returned to the students."])}
  },
  "diciplinary": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convocation to the Disciplinary Council"])},
    "label1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the reasons for which the child is being brought to the disciplinary council."])},
    "error1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select at least one reason."])},
    "label2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of the disciplinary council."])},
    "error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a date."])},
    "label3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time of the council."])},
    "error3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a time."])},
    "violence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physical violence"])},
    "harassment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harassment"])},
    "vandalism": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vandalism"])},
    "substances": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use of prohibited substances"])},
    "insubordination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insubordination"])},
    "onlineBehavior": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inappropriate online behavior"])},
    "absences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeated absences"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summons sent successfully"])}
  },
  "absence": {
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absence Period"])},
    "hours_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Absence Hours"])},
    "enter_hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter number of hours"])},
    "enter_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a number"])},
    "period_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absence Period"])},
    "select_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select period"])},
    "week_label": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " Week"]), _normalize([_interpolate(_named("count")), " Weeks"])])},
    "select_period_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a period"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absence period sent successfully"])}
  },
  "comportement": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow-Up report"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "presence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presence"])},
    "punctuality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punctuality"])},
    "attitude": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attitude"])},
    "interest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interest"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "attitude_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please rate the attitude"])},
    "interest_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please rate the interest"])},
    "success_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow-up report sent successfully"])}
  },
  "attitude": {
    "veryPoor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defiant / Insolent"])},
    "needsImprovement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troublemaker"])},
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiet"])},
    "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good / Appreciable"])},
    "excellent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correct"])},
    "pleaseRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please rate the attitude"])}
  },
  "interest": {
    "veryLow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very little interest"])},
    "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low interest"])},
    "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average interest"])},
    "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High interest"])},
    "veryHigh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very high interest"])},
    "pleaseRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please rate the interest"])}
  },
  "messages": {
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enriched messaging"])},
    "quota_reached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your quota has been reached for today"])},
    "quota_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your 500 messages for the day are exhausted. You can wait until tomorrow or choose another pricing option."])},
    "change_plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change pricing option"])},
    "group_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group recipient"])},
    "individual_recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual recipient"])},
    "select_level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select level"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "all_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All classes"])},
    "select_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select class"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class"])},
    "number_on_eska": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This number is on Eska"])},
    "number_not_on_eska": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This number is not on Eska, they will be invited to download Eska"])},
    "numbers_marked_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numbers marked with"])},
    "will_be_invited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["They will be invited to download the app to receive them."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "title_french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title in French"])},
    "title_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title in English"])},
    "enter_title_french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter title in French"])},
    "enter_title_english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter title in English"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "french_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French version"])},
    "english_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English version"])},
    "add_media": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add media"])},
    "added_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File added"])},
    "file_preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File preview"])},
    "delete_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete file"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "errors": {
      "phone_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter at least one phone number"])},
      "title_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter at least one title (French or English)"])},
      "message_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter at least one message (French or English)"])},
      "character_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message exceeds character limit"])},
      "invalid_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid class number"])},
      "send_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to send message. Please try again."])}
    },
    "success": {
      "campaign_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campaign created successfully!"])},
      "message_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message sent successfully!"])}
    },
    "resend_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend Confirmation"])},
    "confirm_resend_campaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to resend this group message?"])},
    "title_fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title (FR)"])},
    "title_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title (EN)"])},
    "classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classroom"])},
    "sent_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sent Group Messages"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
    "no_messages_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No messages found"])},
    "view_campaign_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Campaign Details"])},
    "resend_campaign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend Group Message"])},
    "message_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message Details"])},
    "french_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French Title"])},
    "english_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English Title"])},
    "french_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French Message"])},
    "english_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English Message"])},
    "attachment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachment"])},
    "attached_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attached Image"])}
  },
  "synchronisation": {
    "search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by name..."])},
    "all_subjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All subjects"])},
    "all_classes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All classes"])},
    "all_statuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All statuses"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed"])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])},
    "revoked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revoked"])},
    "students": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Class"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "no_sync_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No synchronization requests"])},
    "confirm_sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm synchronization"])},
    "confirm_sync_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to synchronize this request?"])},
    "confirm_sync_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The teacher will be able to access the list of students in this class and enter their grades from Eska."])},
    "reject_sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject synchronization"])},
    "reject_sync_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to reject the synchronization request for this request?"])},
    "reject_sync_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The teacher will not be able to access the list of students in this class and will not be able to enter their grades from Eska."])},
    "revoke_sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revoke synchronization"])},
    "revoke_sync_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to revoke this request?"])},
    "resync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronization"])},
    "resync_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to resynchronize this request?"])},
    "select_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the class"])},
    "no_class_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No class available for this level"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "no_sync_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pending requests"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject"])},
    "revoke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revoke"])},
    "synchronized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronized"])},
    "rejected_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejected"])},
    "revoked_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revoked"])},
    "pending_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "message_no_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a class"])},
    "message_sync_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A similar synchronization already exists for this teacher in this class"])},
    "message_sync_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronization completed successfully"])},
    "message_sync_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred during synchronization"])},
    "message_sync_rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronization rejected successfully"])},
    "message_sync_error_reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while rejecting the synchronization"])},
    "message_sync_revoked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronization revoked successfully"])},
    "message_sync_error_revoked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while revoking the synchronization"])},
    "message_error_get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error loading data"])},
    "title_sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to synchronize"])},
    "title_reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to reject"])},
    "title_revoke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to revoke"])},
    "title_resync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to resynchronize"])}
  }
}