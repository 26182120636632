<template>
    <div class="container-fluid b">
        <div class="row mb-4">
            <div class="col-md-4">
                <input type="text" class="form-control" v-model="searchQuery" :placeholder='$t("synchronisation.search_placeholder")'>
            </div>
            <div class="col-md-8">
                <div class="d-flex gap-3">
                    <div class="dropdown mx-3">
                        <button class="dropdown-toggle custom-dropdown" type="button" id="dropdownMenuButton1"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            {{ selectedSubject || 'Toutes les matières' }}
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li @click="selectSubject(null)">
                                <a class="dropdown-item" href="#"> {{$t("synchronisation.all_subjects")}} </a>
                            </li>
                            <li v-for="subject in uniqueSubjects" :key="subject" @click="selectSubject(subject)">
                                <a class="dropdown-item" href="#">{{ subject }}</a>
                            </li>
                        </ul>
                    </div>

                    <div class="dropdown mx-3 ">
                        <button class="dropdown-toggle custom-dropdown" type="button" id="dropdownMenuButton2"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            {{ selectedClass || 'Toutes les classes' }}
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                            <li @click="selectClass(null)">
                                <a class="dropdown-item" href="#">{{$t("synchronisation.all_classes")}}</a>
                            </li>
                            <li v-for="classe in uniqueClasses" :key="classe" @click="selectClass(classe)">
                                <a class="dropdown-item" href="#">{{ classe }}</a>
                            </li>
                        </ul>
                    </div>

                    <div class="dropdown mx-3">
                        <button class="dropdown-toggle custom-dropdown" type="button" id="dropdownMenuButton3"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            {{ selectedStatus || 'Tous les status' }}
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                            <li @click="selectStatus(null)">
                                <a class="dropdown-item" href="#">{{$t("synchronisation.all_statuses")}}</a>
                            </li>
                            <li @click="selectStatus('pending')">
                                <a class="dropdown-item" href="#">{{$t("synchronisation.pending")}}</a>
                            </li>
                            <li @click="selectStatus('confirmed')">
                                <a class="dropdown-item" href="#">{{$t("synchronisation.confirmed")}}</a>
                            </li>
                            <li @click="selectStatus('rejected')">
                                <a class="dropdown-item" href="#">{{$t("synchronisation.rejected")}}</a>
                            </li>
                            <!-- Ajout de l'option "Révoqué" -->
                            <li @click="selectStatus('revoked')">
                                <a class="dropdown-item" href="#">{{$t("synchronisation.revoked")}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="card px-0">
            <div class="card-body px-0">
                <Spinner class="text-center mt-8 mb-8" v-if="loading" style="height: 200px">
                    <div>
                        <span class="mt-4">
                            <Spinner></Spinner>
                        </span>
                    </div>
                </Spinner>
                <table class="table table-borderless mt-4 mb-6"
                    v-else-if="filteredRequests && filteredRequests.length > 0">
                    <thead id="thead">
                        <tr id="thead">
                            <th class="w-20">{{$t("synchronisation.students")}}</th>

                            <th class="w-20" style="padding-left: 4%;">{{$t("synchronisation.subject")}}</th>
                            <th class="w-20" style="padding-left: 4%;">{{$t("synchronisation.class")}}</th>
                            <th class="w-20" style="padding-left: 4%;">{{$t("synchronisation.status")}}</th>
                            <th class="w-20 text-end">{{$t("synchronisation.date")}}</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr id="tr" v-for="request in filteredRequests" :key="request.id">
                            <td class="ps-4">
                                <div class="d-flex">
                                    <img :src="request.photo_url || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBld3h7GuDkpTKb35HgCIgr-N7UXvSDJqbRO_RnDM&s'"
                                        class="avatar avatar-sm me-3" :alt="request.teacher_name"
                                        style="width: 60px; height: 60px; border-radius: 50%; transform:translateY(10px)" />
                                    <div class="name">
                                        {{ request.teacher_name }} <br>
                                        {{ request.phone_number }}
                                    </div>
                                </div>

                            </td>


                            <td style="padding-left: 4%;">{{ request.matiere }}</td>
                            <td style="padding-left: 4%;">{{ request.classroom_name }}
                                <span v-if="request.classroom_number">{{ request.classroom_number }}</span>
                            </td>
                            <td style="padding-left: 4%;">
                                <span class="badge badge-sm bg-transparent text-center px-0"
                                    style="text-transform: capitalize; font-size: 12px;"
                                    v-if="request.status == 'confirmed'">
                                    {{$t("synchronisation.synchronized")}}
                                </span>
                                <span class="badge badge-sm bg-transparent text-danger text-center px-0"
                                    style="text-transform: capitalize; font-size: 12px;"
                                    v-if="request.status == 'rejected'">
                                    {{$t("synchronisation.rejected_status")}}
                                </span>
                                <span class="badge badge-sm bg-transparent text-danger text-center px-0"
                                    style="text-transform: capitalize; font-size: 12px;"
                                    v-if="request.status == 'revoked'">
                                    {{$t("synchronisation.revoked_status")}}
                                </span>
                                <span class="badge badge-sm bg-gradient-warning text-center px-2 py-1"
                                    style="text-transform: capitalize;font-size: 10px;"
                                    v-if="request.status == 'pending'">
                                     {{$t("synchronisation.pending_status")}}
                                </span>

                            </td>
                            <td class="text-end pe-3" id="last_td">
                                <span> {{ formatDate(request.created_at) }}</span>
                                <div class="hover-icons" v-if="request.status === 'pending'">
                                    <i data-bs-toggle="modal" @click="openConfirmModal(request)"
                                        data-bs-target="#confirmModal" :title='$t("synchronisation.title_sync")'
                                        class="fas fa-check text-success hover-icon"></i>
                                    <i data-bs-toggle="modal" @click="openRejectModal(request)"
                                        data-bs-target="#rejectModal" title="Cliquez pour refuser la synchronisation"
                                        class="fas fa-times text-danger hover-icon"></i>
                                </div>
                                <div class="hover-icons" v-if="request.status === 'confirmed'">
                                    <i data-bs-toggle="modal" @click="openDesyncModal(request)"
                                        data-bs-target="#desyncModal"  :title='$t("synchronisation.title_reject")'
                                        class="fas fa-unlink text-warning hover-icon"></i>
                                </div>
                                <div class="hover-icons" v-if="request.status === 'rejected'">
                                    <i data-bs-toggle="modal" @click="openResyncModal(request)"
                                        data-bs-target="#resyncModal"  :title='$t("synchronisation.title_resync")'
                                        class="fas fa-sync text-primary hover-icon"></i>
                                </div>
                                <div class="hover-icons" v-if="request.status === 'revoked'">
                                    <i data-bs-toggle="modal" @click="openResyncModal(request)"
                                        data-bs-target="#resyncModal"  :title='$t("synchronisation.title_revoke")'
                                        class="fas fa-sync text-primary hover-icon"></i>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="else text-center pb-5" v-else>
                    <div class="container">
                        <div class="text mt-3">
                            <img class="image1 w-20" src="@/assets/img/synchro.png" alt="" />

                            <h5 class="mt-4">{{$t("synchronisation.no_sync_requests")}}</h5>


                        </div>
                    </div>
                </div>

                <!-- Après votre table -->
                <div class="d-flex justify-content-center align-items-center mt-4"
                    v-if="filteredData.length > itemsPerPage">
                    <nav aria-label="Page navigation">
                        <ul class="pagination">
                            <!-- Bouton Previous -->
                            <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                <a class="page-link" href="#" @click.prevent="prevPage" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                </a>
                            </li>

                            <!-- Numéros de pages -->
                            <li class="page-item" v-for="page in totalPages" :key="page"
                                :class="{ active: currentPage === page }">
                                <a class="page-link" href="#" @click.prevent="goToPage(page)">
                                    {{ page }}
                                </a>
                            </li>

                            <!-- Bouton Next -->
                            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                <a class="page-link" href="#" @click.prevent="nextPage" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>


            </div>

        </div>
    </div>

    <!-- Confirm Modal -->
    <div class="modal fade" id="confirmModal" tabindex="-1" ref="confirmModal" aria-labelledby="confirmModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="card">
                    <div class="modal-header" style="border-bottom:1px solid rgba(250, 250,250, 0.4) ;">
                        <h6 class="modal-title" id="confirmModalLabel"> {{$t("synchronisation.confirm_sync")}}</h6>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="sync-details" v-if="selectedRequest">
                            <div class="modal-body text-center pt-4 pb-4">
                                <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"></i>
                                <h6 class="mb-4 text-center">{{$t("synchronisation.confirm_sync_message")}}</h6>
                                <p class="mb-4"> {{$t("synchronisation.confirm_sync_p")}} </p>
                            </div>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th style="border:1px solid rgba(250, 250,250, 0.4) ;" scope="col">{{$t("synchronisation.students")}}</th>
                                        <th style="border:1px solid rgba(250, 250,250, 0.4) ;" scope="col">{{$t("synchronisation.subject")}}</th>
                                        <th style="border:1px solid rgba(250, 250,250, 0.4) ;" scope="col">{{$t("synchronisation.class")}}</th>
                                        <th style="border:1px solid rgba(250, 250,250, 0.4) ;" scope="col"> {{$t("synchronisation.date")}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style="border:1px solid rgba(250, 250,250, 0.4) ; " class="ps-4">{{
                                            selectedRequest.teacher_name }}</td>
                                        <td style="border:1px solid rgba(250, 250,250, 0.4) ;" class="ps-4">{{
                                            selectedRequest.matiere }}</td>
                                        <td style="border:1px solid rgba(250, 250,250, 0.4) ;" class="ps-4">{{
                                            selectedRequest.classroom_name }}
                                        </td>
                                        <td style="border:1px solid rgba(250, 250,250, 0.4) ;" class="pe-4 text-end">{{
                                            formatDate(selectedRequest.created_at) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="form-group mb-3 px-3" v-if="availableClassrooms.length > 0">
                            <label class="form-label">{{$t("synchronisation.select_class")}}</label>
                            <div class="checkbox-group mt-2 d-flex flex-wrap gap-3">
                                <div v-for="classroom in availableClassrooms" :key="classroom.id"
                                    class="form-check d-inline-flex align-items-center me-3">
                                    <input type="radio" :id="'classroom-' + classroom.id" :value="classroom.id"
                                        v-model="selectedClassroomId" class="form-check-input me-2">
                                    <label :for="'classroom-' + classroom.id" class="form-check-label"
                                        style="transform: translateY(5px);">
                                        {{ selectedRequest.classroom_name }} {{ classroom.number }}
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div v-else class="alert alert-warning">
                            {{$t("synchronisation.no_class_available")}}
                        </div>


                    </div>
                    <div class="modal-footer" style="border-top:1px solid rgba(250, 250,250, 0.4) ;">
                        <button type="button" style="border: 1px solid rgba(250, 250,250, 0.4);"
                            class="btn  br-transparent" data-bs-dismiss="modal">Annuler</button>
                        <button type="button" class="btn btn-success me-3" @click="handleConfirmSync">
                            <span v-if="isConfirmLoading" class="spinner-border spinner-border-sm me-2"
                                role="status"></span>
                                {{$t("synchronisation.confirm")}}
                        </button>
                    </div>


                </div>
            </div>
        </div>
    </div>

    <!-- Reject Modal -->
    <div class="modal fade" id="rejectModal" tabindex="-1" aria-labelledby="rejectModalLabel" ref="confirmModal"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="card">
                    <div class="modal-header" style="border-bottom:1px solid rgba(250, 250,250, 0.4) ;">
                        <h5 class="modal-title" id="rejectModalLabel"> {{$t("synchronisation.reject_sync")}}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="sync-details" v-if="selectedRequest">
                            <div class="modal-body text-center pt-4 pb-4">
                                <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"></i>
                                <h6 class="mb-4 text-center">{{$t("synchronisation.reject_sync_message")}}</h6>
                                <p class="mb-4"> {{$t("synchronisation.reject_sync_p")}}  </p>
                            </div>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th style="border:1px solid rgba(250, 250,250, 0.4) ;" scope="col">{{$t("synchronisation.students")}}</th>
                                        <th style="border:1px solid rgba(250, 250,250, 0.4) ;" scope="col">{{$t("synchronisation.subject")}}</th>
                                        <th style="border:1px solid rgba(250, 250,250, 0.4) ;" scope="col">{{$t("synchronisation.class")}}</th>
                                        <th style="border:1px solid rgba(250, 250,250, 0.4) ;" scope="col"> {{$t("synchronisation.date")}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style="border:1px solid rgba(250, 250,250, 0.4) ; " class="ps-4">{{
                                            selectedRequest.teacher_name }}</td>
                                        <td style="border:1px solid rgba(250, 250,250, 0.4) ;" class="ps-4">{{
                                            selectedRequest.matiere }}</td>
                                        <td style="border:1px solid rgba(250, 250,250, 0.4) ;" class="ps-4">{{
                                            selectedRequest.classroom_name }}</td>
                                        <td style="border:1px solid rgba(250, 250,250, 0.4) ;" class="pe-4 text-end">{{
                                            formatDate(selectedRequest.created_at) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div class="modal-footer" style="border-top:1px solid rgba(250, 250,250, 0.4) ;">
                        <button type="button" style="border: 1px solid rgba(250, 250,250, 0.4);"
                            class="btn  br-transparent" data-bs-dismiss="modal">Annuler</button>
                        <button type="button" class="btn btn-danger me-3" @click="handleRejectSync">
                            <span v-if="isRejectLoading" class="spinner-border spinner-border-sm me-2"
                                role="status"></span>
                              {{$t("synchronisation.reject")}}
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <!-- Desync Modal -->
    <div class="modal fade" id="desyncModal" tabindex="-1" ref="confirmModal" aria-labelledby="desyncModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="card">
                    <div class="modal-header" style="border-bottom:1px solid rgba(250, 250,250, 0.4) ;">
                        <h6 class="modal-title" id="desyncModalLabel">  {{$t("synchronisation.revoke_sync")}} </h6>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="sync-details" v-if="selectedRequest">
                            <div class="modal-body text-center pt-4 pb-4">
                                <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"></i>
                                <h6 class="mb-2 text-center">{{$t("synchronisation.revoke_sync_message")}}</h6>
                                <p class="mb-4"> {{$t("synchronisation.reject_sync_p")}}  </p>
                            </div>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th style="border:1px solid rgba(250, 250,250, 0.4) ;" scope="col">{{$t("synchronisation.students")}}</th>
                                        <th style="border:1px solid rgba(250, 250,250, 0.4) ;" scope="col">{{$t("synchronisation.subject")}}</th>
                                        <th style="border:1px solid rgba(250, 250,250, 0.4) ;" scope="col">{{$t("synchronisation.class")}}</th>
                                        <th style="border:1px solid rgba(250, 250,250, 0.4) ;" scope="col"> {{$t("synchronisation.date")}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style="border:1px solid rgba(250, 250,250, 0.4) ; " class="ps-4">{{
                                            selectedRequest.teacher_name }}</td>
                                        <td style="border:1px solid rgba(250, 250,250, 0.4) ;" class="ps-4">{{
                                            selectedRequest.matiere }}</td>
                                        <td style="border:1px solid rgba(250, 250,250, 0.4) ;" class="ps-4">{{
                                            selectedRequest.classroom_name }} {{ selectedRequest.classroom_number }}
                                        </td>

                                        <td style="border:1px solid rgba(250, 250,250, 0.4) ;" class="pe-4 text-end">{{
                                            formatDate(selectedRequest.created_at) }}</td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    </div>
                    <div class="modal-footer" style="border-top:1px solid rgba(250, 250,250, 0.4) ;">
                        <button type="button" style="border: 1px solid rgba(250, 250,250, 0.4);"
                            class="btn  br-transparent" data-bs-dismiss="modal">Annuler</button>
                        <button type="button" class="btn btn-warning me-3" @click="handleRevoquer">
                            <span v-if="isDesyncLoading" class="spinner-border spinner-border-sm me-2"
                                role="status"></span>
                                {{$t("synchronisation.revoke")}}
                        </button>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <!-- Resync Modal -->


    <div class="modal fade" id="resyncModal" tabindex="-1" aria-labelledby="resyncModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="card">
                    <div class="modal-header" style="border-bottom:1px solid rgba(250, 250,250, 0.4) ;">
                        <h6 class="modal-title" id="confirmModalLabel"> {{$t("synchronisation.confirm_sync")}}</h6>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="sync-details" v-if="selectedRequest">
                            <div class="modal-body text-center pt-4 pb-4">
                                <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"></i>
                                <h6 class="mb-4 text-center">{{$t("synchronisation.confirm_sync_message")}}</h6>
                            </div>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th style="border:1px solid rgba(250, 250,250, 0.4) ;" scope="col">{{$t("synchronisation.students")}}</th>
                                        <th style="border:1px solid rgba(250, 250,250, 0.4) ;" scope="col">{{$t("synchronisation.subject")}}</th>
                                        <th style="border:1px solid rgba(250, 250,250, 0.4) ;" scope="col">{{$t("synchronisation.class")}}</th>
                                        <th style="border:1px solid rgba(250, 250,250, 0.4) ;" scope="col"> {{$t("synchronisation.date")}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style="border:1px solid rgba(250, 250,250, 0.4) ; " class="ps-4">{{
                                            selectedRequest.teacher_name }}</td>
                                        <td style="border:1px solid rgba(250, 250,250, 0.4) ;" class="ps-4">{{
                                            selectedRequest.matiere }}</td>
                                        <td style="border:1px solid rgba(250, 250,250, 0.4) ;" class="ps-4">{{
                                            selectedRequest.classroom_name }}
                                        </td>
                                        <td style="border:1px solid rgba(250, 250,250, 0.4) ;" class="pe-4 text-end">{{
                                            formatDate(selectedRequest.created_at) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="form-group mb-3 px-3" v-if="availableClassrooms.length > 0">
                            <label class="form-label">{{$t("synchronisation.select_class")}}</label>
                            <div class="checkbox-group mt-2 d-flex flex-wrap gap-3">
                                <div v-for="classroom in availableClassrooms" :key="classroom.id"
                                    class="form-check d-inline-flex align-items-center me-3">
                                    <input type="radio" :id="'classroom-' + classroom.id" :value="classroom.id"
                                        v-model="selectedClassroomId" class="form-check-input me-2">
                                    <label :for="'classroom-' + classroom.id" class="form-check-label"
                                        style="transform: translateY(5px);">
                                        {{ selectedRequest.classroom_name }} {{ classroom.number }}
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div v-else class="alert alert-warning">
                            {{$t("synchronisation.no_class_available")}}
                        </div>


                    </div>
                    <div class="modal-footer" style="border-top:1px solid rgba(250, 250,250, 0.4) ;">
                        <button type="button" style="border: 1px solid rgba(250, 250,250, 0.4);"
                            class="btn  br-transparent" data-bs-dismiss="modal">Annuler</button>
                        <button type="button" class="btn btn-success me-3" @click="handleConfirmSync">
                            <span v-if="isConfirmLoading" class="spinner-border spinner-border-sm me-2"
                                role="status"></span>
                                {{$t("synchronisation.confirm")}}
                        </button>
                        
                    </div>


                </div>
            </div>
        </div>
    </div>

</template>

<script>
 


import { getClassroomSyncRequests, SyncRequests, decline_syncRequests, getGradeCode } from "@/utils/services";
import Spinner from "../components/Spinner.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
    name: 'ClassroomSyncRequests',
    data() {
        return {

            syncRequests: [],
            selectedRequest: null,
            rejectReason: '',
            confirmModal: null,
            rejectModal: null,
            currentPage: 1,
            itemsPerPage: 5,
            searchQuery: '',
            selectedSubject: null,
            selectedClass: null,
            selectedStatus: null,
            uniqueSubjects: [],
            uniqueClasses: [],
            gradesList: [],
            availableClassrooms: [],
            selectedClassroomId: [],
            loading: false,
            isConfirmLoading: false,
            isRejectLoading: false,
            isDesyncLoading: false,
            isResyncLoading: false,
        }

    },

    components: {
        Spinner
    },

    computed: {
        totalPages() {
            return Math.ceil(this.filteredData.length / this.itemsPerPage);
        },
        filteredData() {
            return this.syncRequests
                .filter(request => {
                    const matchesSearch = request.teacher_name.toLowerCase().includes(this.searchQuery.toLowerCase());
                    const matchesSubject = !this.selectedSubject || request.matiere === this.selectedSubject;
                    const matchesClass = !this.selectedClass || request.classroom_name === this.selectedClass;
                    const matchesStatus = !this.selectedStatus || request.status === this.selectedStatus;
                    return matchesSearch && matchesSubject && matchesClass && matchesStatus;
                })
                .sort((a, b) => {
                    const dateA = new Date(a.created_at).getTime();
                    const dateB = new Date(b.created_at).getTime();
                    return dateB - dateA;
                });
        },
        filteredRequests() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.filteredData.slice(start, end);
        }
    },
    methods: {
        async getGradesData() {
            try {
                const grades = await getGradeCode();
                this.gradesList = grades.data;
            } catch (error) {
                console.error('Error fetching grades:', error);
            }
        },

        updateAvailableClassrooms(gradeCode) {
            const grade = this.gradesList.find(g => g.code === gradeCode);
            this.availableClassrooms = grade ? grade.classrooms : [];
            this.selectedClassroomId = null; // Réinitialiser la sélection
        },


        async fetchSyncRequests() {
            this.loading = true;
            try {
                const response = await getClassroomSyncRequests();

                // Vérifier si nous avons une réponse avec des données
                if (response && response.data) {
                    this.syncRequests = response.data.map(request => ({
                        id: request.id,
                        teacher_name: request.teacher_name,
                        phone_number: request.teacher_phone,
                        classroom_name: request.grade_code,
                        created_at: request.created_on,
                        status: this.getStatusFromCode(request.status),
                        photo_url: request.teacher_photo,
                        matiere: request.subject_code,
                        eska_classroom_id: request.eska_classroom_id,
                        classroom_number: request.classroom_number
                    }));

                    // Mettre à jour les filtres uniquement si nous avons des données
                    if (this.syncRequests.length > 0) {
                        this.uniqueSubjects = [...new Set(this.syncRequests.map(r => r.matiere))];
                        this.uniqueClasses = [...new Set(this.syncRequests.map(r => r.classroom_name))];
                    }

                    console.log('Données traitées:', this.syncRequests); // Pour déboguer
                } else {
                    console.error('Pas de données dans la réponse');
                    this.syncRequests = [];
                }

            } catch (error) {
                console.error('Error fetching sync requests:', error);
                toast.error(this.$t('synchronisation.message_error_get'), {
                    autoClose: 3000,
                });
                this.syncRequests = [];
            } finally {
                this.loading = false;
            }
        },


        async handleConfirmSync() {
            this.isConfirmLoading = true;
            try {
                if (!this.selectedRequest || !this.selectedClassroomId) {
                    toast.error(this.$t('synchronisation.message_no_class'), {
                        autoClose: 3000,
                    });
                    return;
                }

                // Vérifier si une synchronisation identique existe déjà
                const existingSynchronization = this.syncRequests.find(request =>
                    request.status === 'confirmed' &&
                    request.matiere === this.selectedRequest.matiere &&
                    request.classroom_name === this.selectedRequest.classroom_name &&
                    request.classroom_number === this.selectedClassroomId &&
                    request.teacher_name === this.selectedRequest.teacher_name &&
                    request.phone_number === this.selectedRequest.phone_number
                );

                if (existingSynchronization) {
                    toast.error(this.$t('synchronisation.message_syns_id'), {
                        autoClose: 3000,
                    });
                    return;
                }


                const syncData = {
                    status: 2,
                    classroom_id: this.selectedClassroomId
                };

                const response = await SyncRequests(syncData, `${this.selectedRequest.id}`);

                if (response.status === 200) {

                     



                    toast.success(this.$t('synchronisation.message_sync_success'), {
                        autoClose: 3000,
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                    this.selectedRequest = null;
                    this.selectedClassroomId = null;
                } else {
                    throw new Error('Erreur lors de la synchronisation');
                }
            } catch (error) {
                console.error('Erreur lors de la confirmation de la synchronisation:', error);
                toast.error(this.$t('synchronisation.message_sync_error'), {
                    autoClose: 3000,
                });
            } finally {
                this.isConfirmLoading = false;
            }
        },

        async handleRejectSync() {
            this.isRejectLoading = true;
            try {
                const syncData = {
                    status: 1,
                };

                const response = await decline_syncRequests(syncData, `${this.selectedRequest.id}`);

                if (response.status === 200) {
                    // Fermer la modal
                    

                    this.selectedRequest = null;
                    toast.success(this.$t('synchronisation.message_sync_rejectted'), {
                        autoClose: 3000,
                    });

                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                } else {
                    throw new Error('Erreur lors du refus de la synchronisation');
                }
            } catch (error) {
                console.error('Erreur lors du refus de la synchronisation:', error);
                toast.error(this.$t('synchronisation.message_sync_error_reject'), {
                    autoClose: 3000,
                });
            } finally {
                this.isRejectLoading = false;
            }
        },



        async handleRevoquer() {
            this.isRejectLoading = true;
            try {
                const syncData = {
                    status: 3,
                };

                const response = await SyncRequests(syncData, `${this.selectedRequest.id}`);

                if (response.status === 200) {
                    // Fermer la modal

                    this.selectedRequest = null;
                    toast.success(this.$t('synchronisation.message_sync_revoked'), {
                        autoClose: 3000,
                    });

                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                } else {
                    throw new Error('Erreur lors du refus de la synchronisation');
                }
            } catch (error) {
                console.error('Erreur lors de la révocation de la synchronisation:', error);
                toast.error(this.$t('synchronisation.message_sync_error_revoked'), {
                    autoClose: 3000,
                });
            } finally {
                this.isRejectLoading = false;
            }
        },



        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },

        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },

        goToPage(page) {
            if (page >= 1 && page <= this.totalPages) {
                this.currentPage = page;
            }
        },
        async openConfirmModal(request) {
            this.selectedRequest = request;
            await this.updateAvailableClassrooms(request.classroom_name);
        },
        openRejectModal(request) {
            this.selectedRequest = request;
        },
        openDesyncModal(request) {
            this.selectedRequest = request;
        },
        async openResyncModal(request) {
            this.selectedRequest = request;
            await this.updateAvailableClassrooms(request.classroom_name);
        },
        formatDate(date) {
            return new Date(date).toLocaleDateString()
        },
        getStatusFromCode(code) {
            switch (code) {
                case 1: return 'rejected';
                case 2: return 'confirmed';
                case 3: return 'revoked';
                default: return 'pending';
            }
        },
        selectSubject(subject) {
            this.selectedSubject = subject;
        },
        selectClass(classe) {
            this.selectedClass = classe;
        },
        selectStatus(status) {
            this.selectedStatus = status;
        }
    },
    created() {
        this.fetchSyncRequests();
        this.getGradesData();
    }
}
</script>

<style scoped>
.detail-item {
    padding: 8px 0;
}

.modal-body {
    padding: 20px;
}

.sync-details,
.reject-details {
    padding: 15px;
    border-radius: 8px;
}

.form-control {
    background-color: rgba(250, 250, 250, 0.1);
    border: 1px solid rgba(250, 250, 250, 0.2);
    color: inherit;
}

.form-control:focus {
    background-color: rgba(250, 250, 250, 0.15);
    border-color: rgba(250, 250, 250, 0.3);
    color: inherit;
    box-shadow: none;
}

.badge {
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 13px;
}

.table td {
    vertical-align: middle;
}

.btn-group .btn {
    margin: 0 2px;
}

#last_td {
    position: relative;
}

#last_td .hover-icons {
    width: max-content;
    position: absolute;
    top: 30px;
    right: 20px;
    display: none;
}

#last_td .hover-icons .hover-icon {
    font-size: 23px;
    font-weight: bold;
}

#tr:hover {
    background: rgba(0, 0, 0, 0.6);
}

#tr:hover .hover-icons {
    display: block;
}

#tr:hover #last_td span {
    filter: blur(3px);
}

#tr {
    border-bottom: 1px solid rgba(250, 250, 250, 0.1) !important;
}

.hover-icon {
    cursor: pointer;
    margin: 0 8px;
    font-size: 16px;
}

.hover-icon:hover {
    transform: scale(1.2);
    transition: transform 0.2s ease;
}

#thead {
    border-bottom: 1px solid rgba(250, 250, 250, 0.1);
}

.custom-dropdown {
    background-color: transparent;
    border: 1px solid rgba(250, 250, 250, 0.2);
    color: inherit;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    position: relative;
}

.custom-dropdown::after {
    content: '\25BC';
    /* Chevron down icon */
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: rgba(250, 250, 250, 0.5);
}

.custom-dropdown:focus {
    border-color: rgba(250, 250, 250, 0.3);
    box-shadow: none;
    outline: none;
}

.dropdown-menu {
    background-color: transparent;
}

.custom-dropdown {
    padding-right: 40px !important;
    /* Add more space on the right for the chevron */
}

.custom-dropdown::after {
    right: 20px;
    /* Adjust chevron position */
    margin-left: 8px;
    /* Add space between text and chevron */
}

.dropdown-menu li {
    border-bottom: 1px solid rgba(250, 250, 250, 0.2);
}

.dropdown-menu li:last-child {
    border-bottom: none;
}

.dropdown-menu .dropdown-item {
    padding: 8px 16px;
    color: white;

}

.dropdown-menu .dropdown-item:hover {
    color: black;
}

img {
    margin-right: 10px;
}


.pagination {
    margin-bottom: 20px;
}

.page-link {
    background-color: transparent;
    border: 1px solid rgba(250, 250, 250, 0.2);
    color: inherit;
}

.page-link:hover {
    background-color: rgba(250, 250, 250, 0.1);
    border-color: rgba(250, 250, 250, 0.3);
    color: inherit;
}

.page-item.active .page-link {
    background-color: rgba(250, 250, 250, 0.2);
    border-color: rgba(250, 250, 250, 0.4);
    color: inherit;
}

.page-item.disabled .page-link {
    background-color: transparent;
    border-color: rgba(250, 250, 250, 0.1);
    color: rgba(250, 250, 250, 0.5);
}
</style>
