mer<template>


    

  <div @mouseleave="isOpen = false" class="class-pa">
    <div class="dic" v-if="loading">
      <Spinner></Spinner>
    </div>
    <div v-else-if="has_class" class="py-4 container-fluid">
      <div v-for="item in schoolList" :key="item.name">
        <div class="row" v-if="item['classrooms'].length">
          <div class="col-lg-12">
            <div class="row">
              <h6>{{ item.name }}</h6>
              <div v-for="classroom in item['classrooms']" :key="classroom.id"
                class="col-lg-3 col-md-6 col-12 mb-3 position-relative" @mouseleave="isOpen = false">
                <ClassesCard @mouseover="openMenu(classroom.id)" :classroom_id="classroom.id"
                  :grade="item.name + ' ' + classroom.number" :speciality_code="classroom.speciality_code !== 'Pas de spécialité'
                    ? classroom.speciality_code
                    : ''
                    " :student_count="classroom.student_count" :overdue="classroom.overdue"
                  :pending_amount="classroom.pending_amount" :pending_payment="0" :iconClass="iconClass"
                  :iconBackground="iconBackground" detail="" directionReverse></ClassesCard>

                <div @mouseover="openMenu(classroom.id)" class="position-absolute" style="
                    cursor: pointer;
                    right: 24px;
                    width: max-content;
                    top: 33px;
                  ">
                  <i class="fas fa-ellipsis-v"></i>

                  <div v-if="isOpen && activeClassroomId === classroom.id" class="menu mt-4"
                    @mouseleave="isOpen = false">
                    <ul class="list-group card">
                      <li class="list-group-item text-light fw-bold">
                        <router-link :to="{
                          name: 'class-details',
                          params: { classId: classroom.id },
                        }">
                          <i class="fa fa-door-open fs-6 mt-2 text-success"
                            style="transform: translateX(-15px); opacity: 0.6" aria-hidden="true"></i>
                          <span style="opacity: 0.6"> {{ $t("classroom.access") }}</span>
                        </router-link>
                      </li>

                      <li class="list-group-item text-light fw-bold">
                        <router-link :to="{
                          name: 'update-class',
                          params: { classId: classroom.id },
                        }">

                          <i class="bi bi-pencil-fill fs-6 mt-2 me-4" style="opacity: 0.6"></i>
                          <span style="opacity: 0.6">
                            {{ $t("button.modify") }}</span>
                        </router-link>
                      </li>
                      <li class="list-group-item text-light fw-bold" data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop7" @click="openDeleteModal(classroom.id)">
                        <a href="#">
                          <i class="fa fa-trash-alt fs-6 mt-2 " style="transform: translateX(-10px); opacity: 0.6"
                            aria-hidden="true"></i>
                          <span style="opacity: 0.6">
                            {{ $t("button.delete") }}</span></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row else mt-5" v-else>
      <div class="container">
        <div class="card w-100">
          <div class="card-body pb-7 pt-5">
            <div class="text">
              <img class="image" src="@/assets/img/classes.png" alt="" />
              <h5 class="mt-4 mb-4">{{ $t("classroom.add_class") }}</h5>
              <p class="mb-4">
                {{ $t("classroom.add_class_text") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--========================= modal delete =================================== -->

    <div class="modal fade" id="staticBackdrop7" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
      aria-labelledby="staticBackdropLabel" aria-hidden="true" :classroomId="selectedClassroomId">
      <div class="modal-dialog">
        <div class="card" style="width: 100%">
          <div class="modal-content">
            <div class="modal-header" style="border-bottom: 1px solid rgba(250, 250, 250, 0.6)">
              <h5 class="modal-title" id="staticBackdropLabel7"></h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center pt-4 pb-4">
              <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"></i>
              <h6>{{ $t("classroom.modal_title") }}</h6>
              <p class="text-justify px-6">
                {{ $t("classroom.modal_text") }}
              </p>
            </div>
            <div class="modal-footer" style="border-top: 1px solid rgba(250, 250, 250, 0.6)">
              <argon-button type="button" class="btn bg-transparent" data-bs-dismiss="modal"
                style="width: 150px; height: 50px; padding-top: 15px; border:1px solid rgba(255, 255, 255, 0.4);">
                {{ $t("button.cancel") }}
              </argon-button>

              <button :disabled="loading" :loading="loading" :active="buttonActive || showConfirmation" color="danger"
                variant="gradient" type="button"
                class="btn btn-danger  d-flex align-items-center justify-content-center"
                style="width: 150px; height: 50px; padding-top: 10px; margin-top: 19px;"
                @click="confirmDelete(selectedClassroomId)">
                <span v-if="!loading">{{ $t('button.confirm') }}</span>
                <div class="spinner-border text-dark" role="status" v-if="loading">
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassesCard from "@/widget/Cards/ClassesCard";
import { getGradeCode, deleteClassroom } from "@/utils/services";
import ArgonButton from "@/components/ArgonButton";
import Spinner from "../components/Spinner.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "classes-list-card",
  data() {
    return {
      iconBackground: "bg-gradient-primary",
      iconClass: "fa fa-ellipsis-vertical",
      schoolList: [],
      verify: null,
      has_class: false,
      loading: false,
      isOpen: false,
      selectedClassroomId: null,
      activeSubMenu: null,
      showConfirmation: false,
    };
  },
  components: {
    ClassesCard,
    Spinner,
    ArgonButton,
  },
  async created() {
    try {
      this.loading = true;

      let classrooms = await getGradeCode();
      this.schoolList = classrooms.data;
      this.schoolList.forEach((classroom) => {
        if (classroom["classrooms"].length) {
          this.has_class = true;
          return;
        }
      });

      return this.schoolList;
    } catch (err) {
      // response.status = err.response.status
      console.error("Failed to get Grades with classrooms", err);
    } finally {
      this.loading = false; // Désactivez le spinner une fois la requête terminée
    }
  },
  classroom_id: {
    type: String,
    required: true,
  },

  methods: {
    openMenu(classroomId) {
      this.activeClassroomId = classroomId;
      this.isOpen = true; // Activer le menu uniquement pour la salle de classe survolée
    },
    closeMenu() {
      this.activeClassroomId = null;
      this.isOpen = false; // Désactiver le menu lorsque vous ne survolez aucune salle de classe
    },
    openDeleteModal(classroomId) {
      this.selectedClassroomId = classroomId;
    },

    async confirmDelete(classroomId) {
      try {
        if (this.loading) {
          return;
        }
        // Désactiver immédiatement le bouton
        this.loading = true;
        this.buttonActive = true;

        const response = await deleteClassroom(classroomId);
        if (response) {
          this.showConfirmation = true;
          const message = this.$t("classroom.delete_message");
          toast.error(message, {
            autoClose: 3000,
          });

          setTimeout(() => {
            window.location.reload();
            this.showConfirmation = false;
          }, 3000);
        }
      } catch (error) {
        const message =
          "Vous ne pouvez pas supprimer cette classe car elle contient des élèves avec des paiements réglés ";
        toast.error(message, {
          autoClose: 4000,
        });
      } finally {
        // Réactiver le bouton après la fin de l'opération
        this.loading = false;
        this.buttonActive = false;
      }
    }

  },
};
</script>

<style scoped>
.card {
  width: 80%;
  text-align: center;
}

.card .card-body {
  margin-left: auto;
  margin-right: auto;
}

.fa-ellipsis-h {
  cursor: pointer;
}

.list-group {
  border-radius: 0;
}




.menu {
  position: absolute;
  top: 0px;
  right: -50px;
  z-index: 30;
  width: 200px;
}
</style>
