<template>
  <main>
    <div class="container-fluid">
      <!-- Header section with background image -->
      <div class="page-header min-height-300"
        style="background-image: url('https://images.unsplash.com/photo-1531512073830-ba890ca4eba2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80')">
        <span class="mask bg-gradient-success opacity-6"></span>
      </div>

      <!-- Profile header card -->
      <div class="card shadow-lg header-profile mt-n6">
        <div class="card-body p-3">
          <div class="row gx-4">
            <div class="col-3">
              <div class="">
                <img v-if="studentDetails.photo_url" width="300" :src="studentDetails.photo_url"
                  class="rounded-circle img-fluid border border-2 border-white" />
                <img v-else width="300" src="@/assets/img/no_body.jpg"
                  class="rounded-circle img-fluid border border-2 border-white" />
              </div>
            </div>

            <div class="col-9 pt-7">
              <div class="row">
                <div class="col-6">
                  <h6 class="text-uppercase text-sm mt-4">
                    {{ studentDetails.first_name }} {{ studentDetails.last_name }}
                  </h6>
                </div>
                <div class="col-6 text-end px-0">
                  <argon-button color="danger" variant="gradient" style="height: 50px; padding-top: 15px; width: 150px"
                    data-bs-toggle="modal" data-bs-target="#staticBackdrop13">
                    {{ $t("button.delete") }}
                  </argon-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Main content section -->
      <div class="py-4 container-fluid">

        <div class="row">
          <div class="col-md-8">
            <div class="card pb-4">
              <div class="card-body">
                 

                <spinner v-if="loading" class="text-center mt-8 mb-8" style="height: 200px">
                  <div><span class="mt-4">
                      <Spinner></Spinner>
                    </span></div>
                </spinner>

                <div class="row" v-else-if="studentDetails">
                  <!-- Student details form -->
                  <div class="col-md-6">
                    <label class="form-control-label">{{ $t("students_detail.number") }}</label>
                    <argon-input type="text" v-model="studentDetails.registration_number" />
                  </div>

                  <div class="col-md-6">
                    <label class="form-control-label">{{ $t("students_detail.last_name") }}</label>
                    <argon-input type="text" v-model="studentDetails.last_name" />
                  </div>

                  <div class="col-md-6">
                    <label class="form-control-label">{{ $t("students_detail.first_name") }}</label>
                    <argon-input type="text" v-model="studentDetails.first_name" />
                  </div>

                  <!-- Enhanced phone input section -->
                   
                  <div class="col-md-6">
                    <label class="form-control-label">{{ $t("students_detail.sexe") }}</label>
                    <div class="d-flex">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" value="M" v-model="studentDetails.gender"
                          id="maleRadio" />
                        <label class="form-check-label" for="maleRadio">{{ $t("students_detail.M") }}</label>
                      </div>
                      <div class="form-check mx-4">
                        <input class="form-check-input" type="radio" value="F" v-model="studentDetails.gender"
                          id="femaleRadio" />
                        <label class="form-check-label" for="femaleRadio">{{ $t("students_detail.F") }}</label>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 mt-3">
                    <label class="form-control-label">{{ $t("students_detail.place_birth") }}</label>
                    <argon-input type="text" v-model="studentDetails.pob" />
                  </div>

                  <div class="col-md-6 mt-3">
                    <label class="form-control-label">{{ $t("students_detail.date") }}</label>
                    <argon-input type="date" v-model="studentDetails.dob" />
                  </div>

                  

                  <div class="col-md-6">
                    <label class="form-control-label">{{ $t("students_detail.phone") }}</label>
                    <div class="tag-input " :class="{ 'required': phoneValidationMessage }"
                      style="min-height: 40px; border: 1px solid rgba(250, 250, 250, 0.4);">
                      <template v-if="studentDetails.phone_list">
                        <div v-for="(phone, index) in parsedPhoneList" :key="index" style="transform: translateY(-3px);" class="tag-input__tag"
                          :title="phone.isValid ? $t('messages.number_on_eska') : $t('messages.number_not_on_eska')"
                          :style="{ backgroundColor: phone.isValid ? '#2980b9' : '#2980b9' }">
                          <img v-if="phone.isValid" src="@/assets/img/eska.png" class="eska-tag-logo me-1" alt="Eska">
                          <i v-else class="bi bi-question-circle-fill me-1"></i>
                          {{ phone.number }}
                          <span @click="removePhone(index)"><i class="fa fa-close"></i></span>
                        </div>
                      </template>
                      <input type="text" :placeholder="$t('new_student.placeholder_number')" class="tag-input__text"
                        @keydown="checkKey" />
                    </div>
                    <span v-if="phoneValidationMessage" class="validation-message">{{ phoneValidationMessage }}</span>
                    <span v-else style="font-size: 12px">{{ $t("students_detail.phone_text") }}</span>
                  </div>

                  
                </div>
                <div class="col-6 mt-4">
                    <argon-button :loading="loading" color="success" full-width @click="saveChanges"
                      style="height: 50px; padding-top: 15px">
                      {{ $t("button.save") }}
                    </argon-button>
                  </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <todo-list-card :total-due="studentDetails.total_due" :current_payment="studentDetails.current_payment"
              :payment_status="studentDetails.payment_status" :students="studentDetails.payments"
              :studentDetails="studentDetails" />
          </div>
        </div>
      </div>
    </div>

    <!-- Delete Modal -->
    <div class="modal fade" id="staticBackdrop13" data-bs-backdrop="static" tabindex="-1">
      <!-- Your existing modal code -->
    </div>
  </main>
</template>

<script>
import { deleteStudent, updateStudent, getStudent, getchecknumberEska } from "@/utils/services";
import ArgonButton from "@/components/ArgonButton";
import ArgonInput from "@/components/ArgonInput.vue";
import TodoListCard from "@/views/components/TodoListCard";
import Spinner from "../components/Spinner.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "student-profile",
  components: { TodoListCard, ArgonInput, ArgonButton, Spinner },

  data() {
    return {
      studentDetails: {},
      loading: false,
      buttonActive: false,
      showConfirmation: false,
      phoneValidationMessage: '',
      eska: false,
    };
  },

  computed: {
    parsedPhoneList() {
      if (this.studentDetails.phone_list) {
        const fixedJson = this.studentDetails.phone_list
          .replace(/'/g, '"')
          .replace(/True/g, 'true')
          .replace(/False/g, 'false');
        return JSON.parse(fixedJson);
      }
      return [];
    }
  },

  created() {
    this.loadStudentDetails();
  },

  methods: {
    close() {
    this.eska = false;
  },
  async checkKey(event) {
  if (event.key === 'Enter' || event.key === ' ' || event.key === ',' || event.key === ';') {
    event.preventDefault();
    let val = event.target.value.trim();

    if (val.length > 0) {
      if (!val.startsWith('237')) {
        val = '237' + val;
      }

      try {
        const response = await getchecknumberEska({
          number_list: [val]
        });

        let phoneList = [];
        if (this.studentDetails.phone_list) {
          phoneList = JSON.parse(this.studentDetails.phone_list
            .replace(/'/g, '"')
            .replace(/True/g, 'true')
            .replace(/False/g, 'false')
          );
        }

        const isValid = response.available?.includes(val) || false;
        
        phoneList.push({
          number: val,
          isValid: isValid
        });

        // Set eska to true if number is not valid
        if (!isValid) {
          this.eska = true;
        }

        this.studentDetails.phone_list = JSON.stringify(phoneList);
        event.target.value = '';
        this.phoneValidationMessage = '';
        

      } catch (error) {
        console.error("Error checking number:", error);
        this.phoneValidationMessage = this.$t("messages.error.phone_verification_failed");
        toast.error(this.$t("messages.error.phone_verification_failed"));
      }
    }
  }
},


    async loadStudentDetails() {
      try {
        this.loading = true;
        const studentId = this.$route.params.id;
        this.studentDetails = await getStudent(studentId);
      } catch (error) {
        console.error("Error loading student details:", error);
        toast.error(this.$t("messages.error.load_failed"));
      } finally {
        this.loading = false;
      }
    },

    removePhone(index) {
      const phoneList = this.parsedPhoneList;
      phoneList.splice(index, 1);
      this.studentDetails.phone_list = JSON.stringify(phoneList);
    },

    async saveChanges() {
      try {
        this.loading = true;
        const response = await updateStudent(this.studentDetails.id, this.studentDetails);
        if (response) {
          toast.success(this.$t("messages.success.update_success"));
          // Reload student details to refresh the data
          await this.loadStudentDetails();
        }
      } catch (error) {
        console.error("Error updating student:", error);
        toast.error(this.$t("messages.error.update_failed"));
      } finally {
        this.loading = false;
      }
    },


    async deleteStudent() {
      try {
        this.loading = true;
        await deleteStudent(this.studentDetails.id);
        toast.success(this.$t("messages.success.delete_success"));
        this.$router.push({
          name: "class-details",
          params: { classId: this.$route.params.classroom_id }
        });
      } catch (error) {
        toast.error(this.$t("messages.error.delete_failed"));
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.header-profile {
  background: transparent !important;
}

.tag-input {
  width: 100%;
  background: transparent;
  border-radius: 10px;
  font-size: 0.9em;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tag-input__tag {
  display: inline-flex;
  align-items: center;
  height: 30px;
  color: white;
  font-size: 14px;
  border-radius: 15px;
  padding: 0 8px;
  margin: 2px;
}

.tag-input__text {
  flex: 1;
  min-width: 120px;
  height: 30px;
  border: none;
  outline: none;
  font-size: 1em;
  background: none;
  color: white;
}

.eska-tag-logo {
  width: 16px;
  height: 16px;
  object-fit: contain;
  vertical-align: middle;
  border-radius: 50%;
}

.bi-question-circle-fill {
  font-size: 14px;
  color: white;
}

.validation-message {
  font-size: 0.875rem;
  color: #f13939;
  font-weight: 500;
}

.tag-input.required {
  border: 2px solid #f13939 !important;
  animation: shake 0.5s;
}

@keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-5px);
  }

  75% {
    transform: translateX(5px);
  }
}
.input-size-45 {
  height: 45px;
  font-size: 16px; /* Ajustez la taille de la police si nécessaire */
}
</style>
