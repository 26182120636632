<template>
  <div class="card text-center w-60 mx-auto">
      <!-- Section Photo de profil -->
      <div class="text-center mt-4">
          <div class="avatar-container mx-auto">
              <img :src="user.photo_url || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBld3h7GuDkpTKb35HgCIgr-N7UXvSDJqbRO_RnDM&s'" 
                   class="avatar-img"
                   alt="Photo de profil">
          </div>
          <h5 class="mt-3">{{ user.firstname }} {{ user.lastname }}</h5>
          <p class="text-muted">{{ user.username }}</p>
      </div>

      <!-- Section Informations -->
      <div class="info-section w-80 mx-auto mt-4 mb-6">
          <div class="info-grid">
              <div class="info-item">
                  <label>{{ $t("profil.username") }}</label>
                  <p class="info-value">{{ user.username }}</p>
                  <hr class="mt-2 horizontal" />
              </div>

              <div class="info-item">
                  <label>Email</label>
                  <p class="info-value">{{ user.email }}</p>
                  <hr class="mt-2 horizontal" />
              </div>

              <div class="info-item">
                  <label>{{ $t("profil.first_name") }}</label>
                  <p v-if="user.firstname" class="info-value">{{ user.firstname }}</p>
                  <p v-else class="info-value">---------</p>
                  <hr class="mt-2 horizontal" />
              </div>

              <div class="info-item">
                  <label>{{ $t("profil.last_name") }}</label>
                  <p v-if="user.lastname" class="info-value">{{ user.lastname }}</p>
                  <p v-else class="info-value">----------</p>
                  <hr class="mt-2 horizontal" />
              </div>

              <div class="info-item">
                  <label>{{ $t("profil.phone") }}</label>
                  <p class="info-value">{{ user.phone }}</p>
                  <hr class="mt-2 horizontal" />
              </div>
          </div>
      </div>
  </div>
</template>

<style scoped>
.avatar-container {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid rgba(250, 250, 250, 0.2);
}

.avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info-section {
  text-align: left;
  padding: 20px;
}
.horizontal {
  background-image: linear-gradient(to right,
      rgba(255, 255, 255, 0),
      white,
      rgba(255, 255, 255, 0)) !important;
  transform: translateX(-40px);
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.info-item {
  padding: 10px;
  border-radius: 8px;
}

.info-item label {
  display: block;
  color: rgba(250, 250, 250, 0.6);
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.info-value {
  margin: 0;
  font-size: 1rem;
  color: white;
  padding: 5px 0;
}

.card {
  background: rgba(0, 0, 0, 0.2);
}
</style>

<script>
import { getinfoMembers } from "@/utils/services";

export default {
  name: "Info-personnelle",
  data() {
      return {
          user: {
              username: '',
              lastname: '',
              firstname: '',
              email: '',
              phone: '',
              photo_url: '' // Ajout du champ pour la photo
          }
      };
  },

  async created() {
      await this.loadUserData();
  },

  methods: {
      async loadUserData() {
          try {
              const username = localStorage.getItem('username');
              if (username) {
                  const userData = await getinfoMembers(username);
                  if (userData) {
                      this.user = {
                          username: userData.username,
                          lastname: userData.last_name,
                          firstname: userData.first_name,
                          email: userData.email,
                          phone: userData.phone,
                          photo_url: userData.photo_url // Assurez-vous que cette propriété existe dans votre API
                      };
                  } else {
                      console.error("Aucune donnée utilisateur reçue");
                  }
              } else {
                  console.error("Aucun nom d'utilisateur trouvé dans le localStorage");
              }
          } catch (error) {
              console.error("Erreur lors du chargement des informations de l'utilisateur:", error);
          }
      }
  }
};
</script>