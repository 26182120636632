<template>
    <div class="modal-dialog">
        <div class="card">
            <div class="modal-content">
                <div class="modal-header" style="border-bottom: 1px solid rgba(255, 255, 255, 0.4)">
                    <h5 class="modal-title" id="staticBackdropLabel10"></h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        @click="close1"></button>
                </div>
                <div class="modal-body text-center pt-4 pb-4">
                    <div class="container">
                        <i style="color: #f1c40f; font-size: 70px" class="bi bi-check2-circle"></i>
                        <h6>{{ $t("modal_pending_success.title") }}</h6>
                        <div class="row mt-5">
                            <div class="col-md-6">
                                <p class=" mt-5">{{ $t("modal_pending_success.description") }}</p>
                                <argon-button type="button" class="btn mb-5 mt-3" color="success" variant="gradient"
                                    @click="ParentSendinvoice" data-bs-dismiss="modal"
                                    style="width: 200px; height: 50px; padding-top: 15px">
                                    <span>
                                        <i class="bi bi-send-fill mx-2"></i>
                                        {{ $t("button.send") }}
                                    </span>
                                </argon-button>
                            </div>
                            <div class="col-md-6">
                                <div class="card-message mb-3">
                                    <div class="notif">
                                        <div class="row">
                                            <div class="col-md-3 pt-2 px-2">
                                                <div class="profil pt-2">  <i class="fa fa-school text-light"></i></div>
                                            </div>
                                            <div class="col-md-9  text-left">
                                                <div class="name mt-1  text-start "> {{ school_name }}</div>
                                                <div class="span mt-1  w-80"> </div>
                                                <div class="span mt-1 w-50"> </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="top"></div>
                                    <div class="body">
                                        <div class="content">
                                            <div class="price mt-6 text-dark fw-bold">
                                                 <span style="font-size:18px; opacity:0.6;" >FCFA </span> <span style="font-size:26px">{{ Number(amount).toLocaleString() }}</span>
                                            </div>
                                            <div class="installment text-dark opacity-6">
                                                {{ $t("modal_pending_success.installment") }} {{ installmentNumber }}
                                            </div>

                                            
                                            <div class="span-2 d-flex text-center">
                                                <div class="icon text-dark opacity-6">
                                                    <i class="bi bi-hourglass-split"></i>
                                                </div> 
                                                <div class="date text-dark opacity-6 ms-3">
                                                    {{ $t("modal_pending_success.due_date") }} : {{ due_date }} 
                                                </div>
                                                 
                                            </div>
                                            <div class="amount text-center ">
                                               {{ $t("modal_pending_success.buy") }}
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <span>{{ $t("modal_pending_success.notification") }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer pe-3" style="border-top: 1px solid rgba(255, 255, 255, 0.4)">
                    <argon-button type="button" class="btn me-6 bg-transparent border" title="Fermer"
                        @click="Parentclose1" data-bs-dismiss="modal"
                        style="width: 200px; height: 50px; padding-top: 15px">
                        <span>{{ $t("button.cancel") }}</span>
                    </argon-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton";
export default {
    name: 'Modal-Pending-Success',
    data() {
        return {
            school_name: "",
        }
    },

    components: {
        ArgonButton
    },
    props: {
        amount: {
            type: Boolean,
            required: true
        },

        installmentNumber: {
            type: Boolean,
            required: true
        },

        due_date: {
            type: Boolean,
            required: true
        },
        close1: {
            type: Function,
            required: true,
        },

        sendinvoice: {
            type: Function,
            required: true,
        },
        refreshComponentData: {
            type: Function,
            required: true,
        }
    },
    methods: {
        ParentSendinvoice() {
            this.sendinvoice();
            this.refreshComponentData();
            this.Parentclose1();
        },
        Parentclose1() {
            this.close1();
            this.refreshComponentData();
        },
    },

    mounted() {
        console.log('mounted');
        this.school_name = localStorage.getItem("leeeeee");
        console.log('school_name:', this.school_name);
    }

}
</script>

<style>
.card-message {
    background: #E4E2FB;
    height: 340px;
    width: 80%;
    position: relative;
    margin-left: auto;
    margin-right: auto;


}

.card-message .notif {
    position: absolute;
    top: -20px;
    width: 95%;
    left: 2%;
    right: auto;
    color: black;
    height: 70px;
    background: white;
    animation: fadeOut 2s ease-in-out infinite, resetPosition 0s 2s ease infinite;
}

.card-message .notif .name {
    opacity: 0.7;
    font-size: 14px;
}

.card-message .notif .profil {
    height: 39px;
    width: 39px;
    border-radius: 100%;
    background: #28225B;
    transform: translateX(15px);

}

.card-message .notif .span {
    content: "";
    display: block;
    height: 5px;
    width: 40%;
    background: #c7c6d4;


}

@keyframes fadeOut {
    0% {
        opacity: 0;
        transform: translateY(0);
    }
    100% {
        opacity: 4;
        transform: translateY(60px); /* L'élément monte légèrement et disparaît */
    }
}

@keyframes resetPosition {
    0% {
        opacity: 1;
        transform: translateY(100%); /* Positionne l'élément en bas */
    }
    100% {
        opacity: 4;
        transform: translateY(0); /* L'élément réapparaît à sa position de départ */
    }
}


.card-message .top {
    background: #28225B;
    height: 50px;
}

.card-message .body .content .span-1 {
    content: "";
    display: block;
    height: 10px;
    width: 60%;
    background: #c7c6d4;
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
}

.card-message .body .content .span-2 {
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 13px;
}

 

.card-message .body .content .span-2 .bi {
    
    transform: translateX(36px)
    
}

.content .amount {
    height: 40px;
    color: white;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    background: #28225B;
    font-size: 14px;
    padding-top: 9px;
    transform: translateY(15px);
}
</style>
