<template>
   
  <div class="container-fluid">
     

    <div class="card px-0 mt-3">
      <!-- Modal -->
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="card">
              <div class="modal-header" style="border-bottom: 1px solid rgba(255, 255, 255, 0.4)">
                <h5 class="modal-title" id="exampleModalLabel"></h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div class="title-liste text-center mt-3 mb-3">
                  <h5>{{ $t("other_payment.period") }} </h5>
                </div>

                <table class="table w-60" style="margin:40px auto;">
                  <thead>
                    <tr>
                      <th class="ps-3" style="border: 1px solid rgba(255, 255, 255, 0.4)" scope="col">{{
                        $t("other_payment.amount") }}</th>
                      <th class="pe-3 text-end" style="border: 1px solid rgba(255, 255, 255, 0.4)" scope="col">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="invoice in currentInvoices" :key="invoice.date">
                      <td class="ps-3" style="border: 1px solid rgba(255, 255, 255, 0.4)">XAF {{
                        Number(invoice.amount).toLocaleString() }}</td>
                      <td class="pe-3 text-end" style="border: 1px solid rgba(255, 255, 255, 0.4)">{{ invoice.date }} /
                        {{ invoice.label }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="modal-footer" style="border-top: 1px solid rgba(255, 255, 255, 0.4)">
                <argon-button type="button" class="btn me-4" color="danger" variant="gradient" data-bs-dismiss="modal"
                  style="width: 150px; height: 50px; padding-top: 15px">
                  {{ $t("button.close") }}
                </argon-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body px-0">
        <div class="row">
          <div class="col-md-6 px-0"></div>
          <div class="col-md-6 text-end" style="padding-right: 30px" v-if="otherpayment.length > 0">
            <router-link to="/only-payment-form">
            <argon-button  

              color="success" size="sm" variant="outline">
              <i class="fas fa-plus me-2"></i>
              {{ $t("button.add") }}
            </argon-button>
          </router-link>
          </div>
        </div>

        <spinner class="text-center mt-8 mb-8" v-if="loading" style="height: 200px">
          <div>
            <span class="mt-4">
              <Spinner></Spinner>
            </span>
          </div>
        </spinner>

        <table class="table px-0 mt-6 mb-6" v-else-if="otherpayment.length > 0">
          <thead>
            <tr style="border-bottom: 1px solid rgba(255, 255, 255, 0.1)">
              <th scope="col" class="border-0">{{ $t("other_payment.label") }}</th>
              <th scope="col" class="border-0">Type</th>
              <th scope="col" class="border-0" style="text-decoration: none">{{ $t("other_payment.due_date") }}</th>
              <th scope="col" class="text-end border-0">{{ $t("other_payment.amount") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="list in otherpayment" :key="list.id"
              style="position: relative; border-bottom: 1px solid rgba(255, 255, 255, 0.1);">
              <td scope="row" class="px-4 border-0" title="Cliquez pour modifier le paiement">
                <router-link :to="{ name: 'update-only-payment', params: { id: list.id } }">
                  <span style="opacity:0.7">{{ list.description }}</span>
                </router-link>
              </td>
              <td class="border-0 px-4" v-if="list.amount !== 0">{{ $t("other_payment.single") }}</td>
              <td class="border-0 px-4" v-if="list.amount == 0">{{ $t("other_payment.recurring") }}</td>
              <td class="border-0">
                <div class="ps-3" v-if="list.amount !== 0">
                  {{ formatDate(list.due_date) }}
                </div>
                <div class="ps-3" v-else :title="$t('other_payment.period_title')" data-bs-toggle="modal"
                  data-bs-target="#exampleModal" @click="fetchPeriods(list)">

                  {{ $t("other_payment.period") }}
                </div>
              </td>
              <td class="text-end  border-0 position-relative">
                <span class="amount ms-4" style="transform:translateY(-15px)" v-if="list.amount !== 0">
                  XAF {{ Number(list.amount).toLocaleString() }}
                </span>
                <span class="amount" v-if="list.amount === 0">
                  --------------
                </span>
                <div class="action position-absolute top-0" style="right: 0">
                  <router-link :to="{ name: 'update-only-payment', params: { id: list.id } }">
                    <button v-if="list.amount !== 0" class="btn btn-link text-secondary mb-0"
                      :title="$t('other_payment.title_icon_edit')">
                      <i style="font-size: 18px" class="bi bi-pencil-fill text-success"></i>
                    </button>
                  </router-link>

                  <router-link :to="{ name: 'update-recurent-payment', params: { id: list.id } }">
                    <button v-if="list.amount == 0" class="btn btn-link text-secondary mb-0"
                      :title="$t('other_payment.title_icon_edit')">
                      <i style="font-size: 18px" class="bi bi-pencil-fill text-success"></i>
                    </button>
                  </router-link>


                  <button class="btn btn-link text-secondary mb-0" :title="$t('other_payment.title_icon_delete')"
                    data-bs-toggle="modal" data-bs-target="#staticBackdrop41" @click="confirmDelete(list.id)">
                    <i style="font-size: 18px" class="fa fa-trash-alt mt-2 text-success" aria-hidden="true"></i>
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>

        <div class="else text-center pb-5" v-else>
          <div class="container">
            <div class="text mt-3">
              <img class="image" src="@/assets/img/groupe.png" alt="" />
              <h5 class="mt-4">{{ $t("other_payment.title_no_payment") }}</h5>

                
                <Button  data-bs-toggle="modal" data-bs-target="#staticBackdrop15"
                  class="btn w-20 fs-6 text-center bg-gradient-success mt-4" style="width: 180px; z-index: 4; display:none">
                  <span class="fa fa-plus-circle m-2"></span>
                  {{ $t("button.add") }}
               </button>
               <router-link to="/only-payment-form"> 
               <Button  
                  class="btn w-20 fs-6 text-center bg-gradient-success mt-4" style="width: 180px; z-index: 4">
                  <span class="fa fa-plus-circle m-2"></span>
                  {{ $t("button.add") }}
               </button>
              </router-link> 
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--========================= modal add payment =================================== -->

  <div class="modal fade" id="staticBackdrop15" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="card">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: 1px solid rgba(255, 255, 255, 0.4)">
            <h6 class="modal-title px-4" id="staticBackdropLabel15">

            </h6>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
          </div>
          <div class="modal-body text-center pt-4 pb-4">
            <div class="content">
              <div class="title-add text-center ">
                <h5>{{ $t("other_payment.modal_add_title") }}</h5>
              </div>

              <div class="row w-90" style="margin:0 auto;">

                <div class="col-6">
                  <div class="card-add" @click="gotoonly">
                    <div class="top">
                      <h6>{{ $t("other_payment.single") }}</h6>
                    </div>
                    <div class="text mt-3">
                      <span>
                        {{ $t("other_payment.modal_add_card1_text") }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="card-add" @click="gotocurent">
                    <div class="top">
                      <h6>{{ $t("other_payment.recurring") }} </h6>
                    </div>
                    <div class="text mt-3">
                      <span>
                        {{ $t("other_payment.modal_add_card2_text") }}
                      </span>
                    </div>
                  </div>
                </div>

              </div>



            </div>


          </div>
          <div class="modal-footer" style="
              padding-right: 33px;
              border-top: 1px solid rgba(255, 255, 255, 0.4);
            ">
            <argon-button type="button"   class="btn bg-transparent border border-info me-4" data-bs-dismiss="modal"
              style="width: 150px; height: 50px; padding-top: 15px">
              {{ $t("button.cancel") }}
            </argon-button>



          </div>
        </div>
      </div>
    </div>
  </div>


  <!--========================= modal delete =================================== -->


  <div class="modal fade" id="staticBackdrop41" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="card">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom:1px solid rgba(255, 255, 255, 0.4);">
            <h5 class="modal-title" id="staticBackdropLabel3"></h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center pt-4 pb-4">
            <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"></i>
            <h6>{{ $t("payment.modal_delete_title") }}</h6>
            <p class="text-justify px-6">{{ $t("payment.modal_delete_text") }}</p>
          </div>
          <div class="modal-footer" style="border-top:1px solid rgba(255, 255, 255, 0.4);">
            <argon-button type="button" class="btn bg-transparent border " data-bs-dismiss="modal"
              style="width: 150px; height: 50px; padding-top: 15px">
              {{ $t("button.cancel") }}
            </argon-button>
            <argon-button :loading="loading" :active="buttonActive || showConfirmation" color="danger" variant="gradient" type="button"
              class="mt-1" style="width: 150px; height: 50px; padding-top: 15px"
              @click="deleteConfiguration(configToDeleteId)">
              {{ $t("button.confirm") }}
            </argon-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ArgonButton from "@/components/ArgonButton";
import { getotherconfig, deleteotherconfig } from "@/utils/services";
import { ref, onMounted } from "vue";
import Spinner from "../components/Spinner.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
 
export default {
  name: "Other-Payment",
  components: { ArgonButton, Spinner },
  data() {
    return {
      show_modal: true,
      show_confirm: false,
      isRecurringPayment: false,
      default_date: true,
      current_date: false,
      single_date: false,
      isOffline: !navigator.onLine,
      dueDate: null,
      currentInvoices: [],
      configToDeleteId: null,
      buttonActive: false,
      showConfirmation: false,


      table: [
        {
          label: "Instription",
          montant: 150000,
          type: "Récurrent",
          date: "25/05/2024",
        },
        {
          label: "Transport",
          montant: 15000,
          type: "Unique",
          date: "25/05/2024",
        },
      ],
    };
  },
  setup() {


    const otherpayment = ref([]);
    const loading = ref(false);

    const fetchotherpayment = async () => {
      try {
        loading.value = true;
        const result = await getotherconfig();

        if (result.status === 200) {

          otherpayment.value = result.data.map((item) => ({
            ...item,
            showCustomDiv: false,
          }));
          this.showModal = true;
        } else {
          console.error(
            "Erreur lors de la récupération des membres :",
            result.error
          );
        }
      } catch (error) {
        console.error(
          "Erreur inattendue lors de la récupération des membres :",
          error
        );
      } finally {
        loading.value = false;
      }
    };



    onMounted(() => {
      fetchotherpayment();
    });

    return {
      otherpayment,
      loading,
    };
  },


  mounted() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    this.intervalId = setInterval(this.checkInternetConnection, 1000);
  },
  beforeUnmount() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {


    confirmDelete(otherconfigId) {
      this.configToDeleteId = otherconfigId;
    },

    async deleteConfiguration(otherconfigId) {
      try {
        if (this.loading) {
          return;
        }

        this.loading = true;
        // Récupérez la configuration pour obtenir la liste des installments


        // Supprimez la configuration
        const deleted = await deleteotherconfig(otherconfigId);

        if (deleted) {

          if (!this.showConfirmation) {
            this.showConfirmation = true;
            const message = "Paiement supprimé avec succès";
            toast.success(message, {
              autoClose: 3000,
            });

            document.body.classList.remove("modal-open");
            const modalBackdrops =
              document.getElementsByClassName("modal-backdrop");
            for (const backdrop of modalBackdrops) {
              backdrop.parentNode.removeChild(backdrop);
            }

            setTimeout(() => {
              this.showConfirmation = false;
              window.location.reload();
              this.showConfirmation = false;
            }, 4000);
          }





        }
      } catch (error) {
        console.error("Error while deleting other payment:", error);
      } finally {
        this.loading = false;
      }
    },





    formatDate(dateString) {
      return dateString.split('T')[0];
    },

    fetchPeriods(payment) {
      // Correction de la chaîne JSON
      const cleanedInvoices = payment.invoices.replace(/'/g, '"');
      this.currentInvoices = JSON.parse(cleanedInvoices);
    },


    gotocurent() {
      this.$router.push({ name: 'recurent-payment-form' })
      document.body.classList.remove("modal-open");
      const modalBackdrops =
        document.getElementsByClassName("modal-backdrop");
      for (const backdrop of modalBackdrops) {
        backdrop.parentNode.removeChild(backdrop);
      }
    },


    gotoonly() {
      this.$router.push({ name: 'only-payment-form' })
      document.body.classList.remove("modal-open");
      const modalBackdrops =
        document.getElementsByClassName("modal-backdrop");
      for (const backdrop of modalBackdrops) {
        backdrop.parentNode.removeChild(backdrop);
      }
    },

    gotoupdateonly() {
      this.$router.push({ name: 'update-only-payment' })

    },
    gotoupdatecurent() {
      this.$router.push({ name: 'update-recurent-payment' })

    },


    updateOnlineStatus() {
      this.isOffline = !navigator.onLine;
      this.checkInternetConnection();
    },

    async checkInternetConnection() {
      try {
        const response = await fetch("https://www.google.com", {
          mode: "no-cors",
        });
        if (response.ok || response.type === 'opaque') {
          this.isOffline = false;
        } else {
          this.isOffline = true;
        }
      } catch (error) {
        this.isOffline = true;
      }
    },


  },
};
</script>

<style scoped>
input.date-input-custom::-webkit-datetime-edit-year-field {
  display: none;
}

/* Personnaliser l'apparence de l'input avec la classe date-input-custom */
input.date-input-custom {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px;
  box-sizing: border-box;
}

.else .image {
  width: 300px;
  margin-top: 10px;
}

.card .table thead th {
  width: 30%;
}

.card .table tbody tr:hover td .amount {
  filter: blur(2px);
}

.card .table tbody tr {
  transition: 0.7s;
  cursor: pointer;
}

.action {
  display: none;
}

.card .table tbody tr:hover .action {
  display: block;
}

.table tbody tr td {
  padding-top: 13px;
  padding-bottom: 13px;
}

table tbody tr:hover td .button {
  display: block;
}

.table tbody tr:hover td .amount p {
  filter: blur(2px);
}

.table thead tr th {
  width: 20%;
}

.td-warning {
  position: relative;
}

.warning {
  font-size: 20px;
  position: absolute;
  right: 90px;
  top: 6px;
}

.text-warning1 {
  background: white;
  color: black;
  position: absolute;
  top: -45px;
  right: 100px;
  padding: 15px 30px;
}

/*=======css table ====*/

.custom-div {
  position: absolute;
  background-color: white;
  padding: 10px;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  display: none;
  margin-bottom: -30px;
  /* Ajoutez cette ligne pour compenser le déplacement vers le haut */
  margin-left: -10px;
  /* Ajoutez cette ligne pour compenser le déplacement vers la gauche */
}

.custom-div-active {
  display: block;
}

/*=======css modal ====*/
.modal {
  background: rgba(0, 0, 0, 0.4);
}

.modal .modal-dialog {
  max-width: 55%;
  width: 55;
  margin-left: 30%;
}

.modal .modal-dialog .modal-content {
  background: transparent;
}

.t-titre {
  width: max-content;
  margin-left: 4%;
}

.table tbody tr td {
  padding-top: 13px;
  padding-bottom: 13px;
}

table tbody tr:hover td .button {
  display: block;
}

.table tbody tr:hover td .amount p {
  filter: blur(2px);
}

.table tbody tr:hover td .alert {
  filter: blur(4px);
}

.table thead tr th {
  width: 20%;
}

.td-warning {
  position: relative;
}

.warning {
  font-size: 20px;
  position: absolute;
  right: 90px;
  top: 6px;
}

.text-warning1 {
  background: white;
  color: black;
  position: absolute;
  top: -45px;
  right: 100px;
  padding: 15px 30px;
}

@media (max-width: 767px) {
  .card .card-body .table-responsive {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .modal .modal-dialog {
    max-width: 90%;
    width: 90%;
    margin-left: 5%;
    height: 80%;
  }

  .t-titre {
    width: max-content;
    margin-left: 0%;
  }

  .card .table tbody .else .button-else {
    display: none;
  }
}

@media (min-width: 768px) {
  .card .card-body .table-responsive {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}


.card-add {
  border: 1px solid green;
  margin: 40px 0px;
  padding: 15px 0px;
  cursor: pointer;
  transition: 0.6s ease-out;
  height: 150px;
}


.card-add:hover {
  border: 1px solid white;
  transform: translateY(-3px)
}
</style>
