<template>
  <div class="container-fluid hov py-4">
    <div class="card-api mt-4 mb-6 px-3" v-if="cota"
      style="border: 1px solid rgba(206, 85, 66, 0.5); border-radius: 5px; background: #111c44;">
      <div class="card-api-content d-flex justify-content-between">
        <div class="d-flex mt-3 pt-1">
          <div class="key pe-2 me-3">
            <i class="bi bi-exclamation-circle-fill fs-1 mt-3 text-danger"></i>
          </div>
          <div class="text mx-3">
            <h6 style="font-size:14px;">{{ $t('messages.quota_reached') }}</h6>
            <p style="font-size: 14px;">{{ $t('messages.quota_message') }}</p>
            <p style="font-size: 14px; margin-top: -10px;">
              <a style="color:#4f61c4 !important;" target="_blank" href="#">{{ $t('messages.change_plan') }}</a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="card mt-n3">
      <div class="card-body d-flex">
        <form class="w-80" style="margin:0 auto;" @submit.prevent="sendMessage">
          <div class="card-header p-0 text-start"></div>
          <div class="row pt-0">
            <div class="mb-4">
              <div class="card-header px-0 d-flex mb-1">
                <div class="form-check">
                  <input type="radio" id="scolarite" value="scolarite" v-model="activeTab" class="form-check-input">
                  {{ $t('messages.group_recipient') }}
                </div>
                <div class="form-check ms-5">
                  <input type="radio" id="autre" value="autre" v-model="activeTab" class="form-check-input">
                  {{ $t('messages.individual_recipient') }}
                </div>
              </div>

              <div class="row">
                <div class="col-md-6" v-if="activeTab === 'scolarite'">
                  <div class="row pt-2 pb-3">
                    <div class="col-md-6">
                      <label for="grade">{{ $t('messages.select_level') }}</label>
                      <select class="form-select" aria-label="Default select example" @change="updateGradeCode($event)">
                        <option selected>{{ $t('messages.select') }}</option>
                        <option v-for="grade in GRADE_CODE" :key="grade.id" :value="grade.code">
                          {{ grade.name }}
                        </option>
                      </select>
                    </div>

                    <div class="col-md-6" v-if="grade_code && grade_code !== $t('messages.select')">
                      <label for="classroom">{{ $t('messages.select_class') }}</label>
                      <select class="form-select" v-model="selectedClass" @change="handleClassroomChange">
                        <option value="" selected>{{ $t('messages.all_class') }}</option>
                        <option v-for="classroom in selectedGradeClassrooms" :key="classroom.id" :value="classroom">
                          {{ $t('messages.class') }} {{ classroom.number }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="col-md-6" v-if="activeTab === 'autre'">
                  <label style="transform:translateY(14px)">{{ $t("new_student.phone") }}</label>
                  <p class="mt-0 py-0 px-1" style="font-size: 12px; transform:translateY(7px);">
                    {{ $t("new_student.phone_text") }}
                  </p>

                  <div class="tag-input" style="height: 50px; border: 1px solid rgba(250, 250, 250, 0.4)">
                    <div v-for="(tag, index) in tags" :key="index" class="tag-input__tag"
                      :title="tag.isValid ? $t('messages.number_on_eska') : $t('messages.number_not_on_eska')" :style="{
                        backgroundColor: tag.isValid ? '#2980b9' : '#dc3545',
                        opacity: tag.isValid ? '1' : '0.8',
                        cursor: tag.isValid ? 'pointer' : 'pointer'
                      }">
                      <img v-if="tag.isValid" src="@/assets/img/eska.png" class="eska-tag-logo me-1" alt="Eska">
                      <i v-else class="bi bi-question-circle-fill me-1"></i>
                      {{ tag.number }}
                      <span @click="removeTag(index)"><i class="fa fa-close"></i></span>
                    </div>
                    <input type="text" :placeholder="$t('new_student.placeholder_number')"
                      class="tag-input__text border-0" @keydown="checkKey" />
                  </div>
                  <small class="text-danger" v-if="errors.phones">{{ errors.phones }}</small>
                </div>

                <div v-if="eska" class="card-api mt-4 px-3 pb-3"
                  style="border: 1px solid rgba(206, 85, 66, 0.5); border-radius: 5px; background: #111c44; margin-left: 13px; width: 96%;">
                  <div class="card-api-content d-flex justify-content-between">
                    <div class="d-flex mt-3 pt-1">
                      <div class="key">
                        <i class="bi bi-exclamation-circle-fill fs-3 mt-n1 text-danger"></i>
                      </div>
                      <div class="text mx-3">
                        <h6 style="font-size: 12px; position:relative;transform:translateY(10px)" class="d-flex">
                          {{ $t('messages.numbers_marked_with') }} <p class="border mx-2 text-center"
                            style="width: 25px; border-radius: 50%; height: 25px;">?</p>
                          {{ $t('messages.will_be_invited') }}
                        </h6>
                      </div>
                    </div>
                    <div title="Fermer la modale" class="close">
                      <i id="close" @click="close" class="fa fs-3 mt-2 fa-times"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <label for="message" style="color: grey; font-size:16px;">{{ $t('messages.title') }}<em
                  style="color: #f60000">*</em></label>
              <div class="mb-3 col-6">
                <label for="titleFr" style="color: grey;">{{ $t('messages.title_french') }}</label>
                <input type="text" class="form-control" id="titleFr" v-model="title_fr" style="height: 50px"
                  :placeholder="$t('messages.enter_title_french')">
                <small class="text-danger" v-if="errors.title_fr">{{ errors.title_fr }}</small>
              </div>

              <div class="mb-3 col-6 pe-0">
                <label for="titleEn" style="color: grey;">{{ $t('messages.title_english') }}</label>
                <input type="text" class="form-control" id="titleEn" v-model="title_en" style="height: 50px"
                  :placeholder="$t('messages.enter_title_english')">
                <small class="text-danger" v-if="errors.title_en">{{ errors.title_en }}</small>
              </div>
            </div>

            <div class="row pe-0 mt-1">
              <label for="message" style="color: grey; font-size:16px;">{{ $t('messages.message') }}<em
                  style="color: #f60000">*</em></label>
              <div class="mb-3 col-6">
                <label for="message" style="color: grey;">{{ $t('messages.french_version') }}</label>
                <rich-text-editor v-model="body_fr" @validationStatus="handleFrValidation">
                </rich-text-editor>
                <small class="text-danger" v-if="errors.body_fr">{{ errors.body_fr }}</small>
              </div>

              <div class="mb-3 col-6">
                <label for="message" style="color: grey;">{{ $t('messages.english_version') }}</label>
                <rich-text-editor style="min-height: 400px; border: 1px solid rgba(250, 250, 250, 0.4); color: white"
                  v-model="body_en" @validationStatus="handleEnValidation">
                </rich-text-editor>
                <small class="text-danger" v-if="errors.body_en">{{ errors.body_en }}</small>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="top">
                  <label for="media" style="color: grey;font-size:16px;">{{ $t('messages.add_media') }}</label><br>
                  <input style="height: 50px; padding-top: 13px; border: none;" type="file" id="media"
                    @change="handleFileUpload">
                </div>
                <div class="media-preview w-80 mt-3 ps-0" v-if="mediaFile">
                  <h6>{{ $t('messages.added_file') }}:</h6>
                  <div class="media-item">
                    <div class="media-info">
                      <img v-if="isImage(mediaFile)" :src="mediaFile.preview" class="img-thumbnail"
                        :alt="$t('messages.file_preview')">
                      <span v-else>{{ mediaFile.name }}</span>
                    </div>
                    <button @click="removeFile" class="btn btn-danger" id="btn-danger"
                      :title="$t('messages.delete_file')">
                      <i class="bi bi-x-lg"></i>
                    </button>
                  </div>
                </div>
                <div id="alerte" class="alert ps-0 mt-3 d-flex justify-content-between align-items-center text-center"
                  v-if="errorMessage">
                  <span>{{ errorMessage }}</span>
                  <button type="button" class="btn-close" aria-label="Close" @click="closeErrorMessage">
                    <i class="bi bi-x-lg"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="col-6 mt-3">
              <div class="row mb-3 ps-2 text-start">
                <button id="send_message" type="submit" class="btn btn-success w-50" :disabled="isLoading">
                  <span v-if="!isLoading">
                    <i style="margin-right: 6px;" class="fs-5 fa fa-paper-plane text-light" aria-hidden="true"></i>
                    {{ $t('messages.send') }}
                  </span>
                  <span v-else>
                    <i class="fa fa-spinner fa-spin"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="list mb-10 mt-n6">
        <ListMessage :campaigns="campaigns" :is-loading="isLoading" />
      </div>
    </div>
  </div>
</template>

<script>
import RichTextEditor from '../components/TextEditor.vue';
import ListMessage from './ListMessage.vue';
import { getGradeCode, getSpeciality, uploadFile, sendmessage, getAlertMessages, Campaigns, getchecknumberEska, getCampaigns } from "@/utils/services";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";





/**
 * The main Vue component for the Message view in the Eska application.
 * This component handles the creation and sending of messages, including the ability to upload media files.
 * It also manages the display of a list of messages and the Eska modal.
 */
export default {
  name: "Message_Vue",
  components: { RichTextEditor, ListMessage },
  data() {
    return {
      grade_code: "", // Par défaut vide
      messages: [],
      isValidFr: true,
      isValidEn: true,
      eska: false,
      mediaFile: null,
      errorMessage: "",
      quota_day: false,
      activeTab: 'scolarite',
      activeIndex: null,
      quota_month: false,
      recipientQuery: "",
      filteredStudents: [],
      GRADE_CODE: [],
      schoolList: [],
      selectedClassroom: "",
      showEskaModal: false,
      fileURL: null,
      phoneNumbers: [],
      title_en: "",
      title_fr: "",
      body_fr: "",
      body_en: "",
      phone_list: [],
      badge: "",
      selectedClass: "",
      media: "",
      phoneList: [],
      currentPhone: '',
      tags: [], // Add this line
      isLoading: false,
      selectedClassroomNumber: null,
      campaigns: [],
      errors: {
        phones: '',
        title_fr: '',
        title_en: '',
        body_fr: '',
        body_en: ''
      },

      content: '',
      characterCount: 0,
      maxCharacters: 200, // Limite de caractères
      tinymceConfig: {
        height: 300,
        menubar: true,
        plugins: [
          'advlist autolink lists link charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
          'emoticons template textpattern',
          'hr directionality textcolor colorpicker'
        ],
        toolbar: [
          'undo redo | formatselect | bold italic underline strikethrough | superscript subscript',
          'alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist',
          'forecolor backcolor | removeformat | hr | link | charmap emoticons',
          'fontselect fontsizeselect | ltr rtl | help'
        ],
        fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
        textpattern_patterns: [
          { start: '*', end: '*', format: 'italic' },
          { start: '**', end: '**', format: 'bold' },
          { start: '#', format: 'h1' },
          { start: '##', format: 'h2' },
          { start: '###', format: 'h3' },
          { start: '####', format: 'h4' },
          { start: '#####', format: 'h5' },
          { start: '######', format: 'h6' },
          { start: '1. ', cmd: 'InsertOrderedList' },
          { start: '* ', cmd: 'InsertUnorderedList' },
          { start: '- ', cmd: 'InsertUnorderedList' }
        ]
      }

    };
  },
  computed: {
    selectedGradeClassrooms() {
      const grade = this.GRADE_CODE.find(g => g.code === this.grade_code);
      if (grade && grade.classrooms) {
        return grade.classrooms.map(classroom => ({
          ...classroom,

        }));
      }
      return [];
    }
  },

  mounted() {
    this.fetchGrades();
    this.fetchCampaigns();
  },

  watch: {
    content(newContent) {
      this.characterCount = newContent.length;

      // Bloquer si le contenu dépasse la limite
      if (this.characterCount > this.maxCharacters) {
        this.content = newContent.slice(0, this.maxCharacters);
      }
    }
  },
  methods: {

    async fetchCampaigns() {
      this.isLoading = true;
      try {
        const response = await getCampaigns();
        this.campaigns = response;
      } catch (error) {
        console.error('Error fetching campaigns:', error);
      } finally {
        this.isLoading = false;
      }
    },


    handleClassroomChange() {
      if (this.selectedClass) {
        this.selectedClassroomNumber = this.selectedClass.number;
        console.log('Selected classroom number:', this.selectedClassroomNumber); // Pour debug
      } else {
        this.selectedClassroomNumber = null;
      }
    },
    updateClassroom(event) {
      const selectedClassId = parseInt(event.target.value);
      const selectedClass = this.selectedGradeClassrooms.find(
        classroom => classroom.id === selectedClassId
      );

      if (selectedClass) {
        this.selectedClassroomNumber = selectedClass.number;
        console.log('Selected classroom number:', selectedClass.number);
      }
    },



    updateGradeCode(event) {
      this.grade_code = event.target.value;
    },

    async fetchGrades() {
      const response = await getGradeCode();
      if (response.status === 200) {
        this.GRADE_CODE = response.data;
      }
    },


    async fetchMessages() {
      try {
        const response = await getAlertMessages();
        this.messages = response;
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    },
    handleFrValidation(isValid) {
      this.isValidFr = isValid;
    },

    handleEnValidation(isValid) {
      this.isValidEn = isValid;
    },

    updateCharacterCount() {
      this.characterCount = this.content.length;

      if (this.characterCount >= this.maxCharacters) {
        this.content = this.content.slice(0, this.maxCharacters);
        this.characterCount = this.maxCharacters;
      }
    },

    onEditorChangeFr(value) {
      // Met à jour `body_fr` avec la nouvelle valeur
      this.body_fr = value;
    },
    onEditorChangeEn(value) {
      // Met à jour `body_en` avec la nouvelle valeur
      this.body_en = value;
    },


    async checkKey(event) {
      if (event.key === 'Enter' || event.key === ' ' || event.key === ',' || event.key === ';') {
        event.preventDefault();
        let val = event.target.value.trim();

        if (val.length > 0) {
          // Add 237 prefix if not already present
          if (!val.startsWith('237')) {
            val = '237' + val;
          }

          try {
            const response = await getchecknumberEska({
              number_list: [val]
            });

            if (response.available?.includes(val)) {
              this.tags.push({
                number: val,
                isValid: true
              });
              this.eska = false;
            } else {
              this.tags.push({
                number: val,
                isValid: false
              });
              this.eska = true;
            }
            event.target.value = "";
          } catch (error) {
            console.error("Error checking number:", error);
          }
        }
      }
    },

    addTag(event) {
      let val = event.target.value.trim();
      if (val.length > 0) {
        let newTags = val.split(/[,;]/);
        newTags.forEach(tag => {
          tag = tag.trim();
          if (tag.length > 0 && !this.tags.includes(tag)) {
            this.tags.push(tag);
          }
        });
        event.target.value = "";
      }
    },
    removeTag(index) {
      this.tags.splice(index, 1);
    },
    removeLastTag(event) {
      if (event.target.value.length === 0) {
        this.removeTag(this.tags.length - 1);
      }
    },

    toggleDetails(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },

    async getData() {
      let grades = await getGradeCode();
      this.GRADE_CODE = grades.data;
      this.grade_code = grades.data[0].name;
      this.grade_name = grades.data[0].name;
      let specialities = await getSpeciality();
      this.SPECIALITY_CODE = specialities.data;


      if (specialities.data.length > 0 && specialities.data[0].short_name) {
        this.speciality = specialities.data[0].short_name;
      } else {
        this.speciality = null;
      }
    },

    async sendMessage() {
      this.errors = {
        phones: '',
        title_fr: '',
        title_en: '',
        body_fr: '',
        body_en: ''
      };

      if (this.title_fr && !this.title_en) {
        this.title_en = this.title_fr;
      } else if (this.title_en && !this.title_fr) {
        this.title_fr = this.title_en;
      }

      // Sync message bodies if one is empty  
      if (this.body_fr && !this.body_en) {
        this.body_en = this.body_fr;
      } else if (this.body_en && !this.body_fr) {
        this.body_fr = this.body_en;
      }

      let hasErrors = false;

      if (this.activeTab === 'autre' && this.tags.length === 0) {
        this.errors.phones = this.$t('messages.errors.phone_required');
        hasErrors = true;
      }

      if (!this.title_fr && !this.title_en) {
        this.errors.title_fr = this.$t('messages.errors.title_required');
        this.errors.title_en = this.$t('messages.errors.title_required');
        hasErrors = true;
      }

      if (!this.body_fr && !this.body_en) {
        this.errors.body_fr = this.$t('messages.errors.message_required');
        this.errors.body_en = this.$t('messages.errors.message_required');
        hasErrors = true;
      }

      if (hasErrors) return;

      if (!this.isValidFr || !this.isValidEn) {
        alert(this.$t('messages.errors.character_limit'));
        return;
      }

      this.isLoading = true;
      try {
        if (this.activeTab === 'scolarite') {
          const classNumber = String(this.selectedClassroomNumber); // Convert to string

          const criteria = {
            grade_code: this.grade_code,
            ...(classNumber && { number: classNumber })
          };

          console.log('Sending campaign with criteria:', criteria);

          const response = await Campaigns({
            en_title: this.title_en,
            fr_title: this.title_fr,
            en_body: this.body_en,
            fr_body: this.body_fr,
            media: this.fileURL || "",
            criteria: criteria
          });


          console.log('Campaign response:', response); // Debug log

          this.clearForm();
          this.clearForm();
          toast.success(this.$t('messages.success.campaign_created'), {
            autoClose: 3000,
          })
        } else {
          const messageData = {
            title_fr: this.title_fr,
            title_en: this.title_en,
            body_fr: this.body_fr,
            body_en: this.body_en,
            media: this.fileURL || "",
            phone_list: this.tags.map(tag => tag.number),
          };

          await sendmessage(messageData);
          this.clearForm();
          toast.success(this.$t('messages.success.message_sent'), {
            autoClose: 3000,
          });
        }
      } catch (error) {
        console.error("Erreur lors de l'envoi du message :", error);
        this.errorMessage = this.$t('messages.errors.send_failed');
      } finally {
        this.isLoading = false;
      }
    },


    // Add a helper method to clear the form
    clearForm() {
      this.title_fr = "";
      this.title_en = "";
      this.body_fr = "";
      this.body_en = "";
      this.mediaFile = null;
      this.fileURL = null;
      this.phoneList = [];
      document.getElementById("media").value = "";
    },


    close() {
      this.eska = false;
    },




    async handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const isValidImage = file.type.match(/image\/(jpg|jpeg|gif|png)/) && file.size <= 5242880;
        const isValidVideo = file.type === 'video/mp4' && file.size <= 5242880;

        if (isValidImage || isValidVideo) {
          const reader = new FileReader();
          reader.onload = async (e) => {
            file.preview = e.target.result;
            this.mediaFile = file;
            this.errorMessage = "";

            // Get the upload response
            let res = await uploadFile(file);
            // Use the URL instead of path
            this.fileURL = res.url;
          };
          reader.readAsDataURL(file);
        } else {
          this.errorMessage = "Seules les images (jpg, jpeg, gif, png) de taille maximale 5 Mo et les vidéos (mp4) de taille maximale 5 Mo sont autorisées.";
          this.removeFile();
        }
      }
    },




    removeFile() {
      this.mediaFile = null;
      document.getElementById("media").value = "";
    },
    isImage(file) {
      return file.type.startsWith('image/');
    },
    closeErrorMessage() {
      this.errorMessage = "";
    },

    filterStudents() {
      const query = this.recipientQuery.toLowerCase();
      if (query === "") {
        this.filteredStudents = [];
      } else {
        this.filteredStudents = this.students.filter(student => student.name.toLowerCase().includes(query));
      }
    },
    selectStudent(student) {
      this.recipientQuery = `${student.name}\n${student.parentPhone} - ${student.class}`;
      this.filteredStudents = [];
      if (student.eska.toLowerCase() === 'non') {
        this.eska = true;
      }
    }
  },
  beforeMount() {
    this.getData();
  },


};
</script>

<style scoped>
/* Applique la bordure blanche et un fond transparent aux champs de formulaire */
input[type="text"],
input[type="file"],
select.form-select {
  background-color: transparent;
  /* Fond transparent */
  border: 1px solid rgba(250, 250, 250, 0.4);
  color: white;
  /* Couleur du texte blanche pour une meilleure visibilité */
}

input[type="text"]:focus,
input[type="file"]:focus,
select.form-select:focus {
  outline: none;
  /* Enlève l'ombre de focus */
  border-color: gray;
  /* Bordure de couleur personnalisée lors du focus */
}

/* Pour ajuster l'apparence du placeholder */
input[type="text"]::placeholder,
select.form-select option {
  color: rgba(255, 255, 255, 0.6);
  background: transparent;
  /* Couleur du placeholder en blanc translucide */
}




.container-fluid {
  background-color: transparent;
}

.card {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.card-header {

  margin-bottom: 30px;
}

.card-body {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.media-preview {
  flex: 0 0 30%;
}

.media-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.media-info {
  display: flex;
  align-items: center;
}

.img-thumbnail {
  max-width: 50px;
  max-height: 50px;
  margin-right: 10px;
}

.btn-success:hover {
  background: transparent;
}

#exampleInputEmail1 {
  background: none;
}

#icon i {
  cursor: pointer;
}

#send_message {
  height: 50px;
  margin-top: 30px;
  border: 1px solid #2dceb1;
}

#btn-danger {
  transform: translateY(10px);
  border-radius: 100%;
  height: 40px;
  width: 40px;
  position: relative;
  opacity: 0.8;
}

#btn-danger .bi {
  position: absolute;
  left: 13px;
  top: 10px;
  font-weight: bold;
}



.alert {
  max-width: 80%;
  margin: 0 auto;
}

.btn-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  line-height: 1;
  color: #000;
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: -10px;
  background: white;
  color: red;
  opacity: 1;
  border-radius: 50%;
}

#alerte {
  background: rgba(0, 0, 0, 0.6);
  transform: translateX(-50px);
  padding: 10px;
  opacity: 0.4;
  border: 2px solid #ce5542;
  position: relative;
  color: #ce5542;

}

.hov {
  position: relative;
}

#li {
  border: 1px solid rgba(250, 250, 250, 0.4);
  padding: 10px 15px;
  list-style: none;
  transition: 0.6;
}

#li:hover {
  background: rgba(0, 0, 0, 0.6);
}

#li .li {
  justify-content: space-between;
}

#close {
  cursor: pointer;
  transition: 0.3s;

}

#close:hover {
  color: #ce5542;
}

textarea {
  min-height: 38px;
  height: auto;
}

.phone-input-container {
  position: relative;
}

.phone-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
}

.phone-tag {
  background-color: #75acff;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.phone-tag i {
  cursor: pointer;
  font-size: 14px;
}

/* Add this to your existing styles */
:deep(.quill-editor) {
  height: 400px !important;
}

:deep(.ql-container) {
  height: 350px !important;
}

:deep(.ql-editor) {
  height: 350px !important;
}


.phone-tag i:hover {
  opacity: 0.8;
}

.tag-input {
  width: 100%;
  border: 1px solid #d9dfe7;
  background: transparent;
  border-radius: 10px;
  font-size: 0.9em;
  min-height: 45px;
  box-sizing: border-box;
  padding: 0 10px;
  margin-bottom: 10px;
}

.tag-input__tag {
  height: 24px;
  color: white;
  float: left;
  font-size: 14px;
  margin-right: 10px;
  background-color: #2980b9;
  border-radius: 15px;
  margin-top: 10px;
  line-height: 24px;
  padding: 0 8px;
}

.tag-input__tag>span {
  cursor: pointer;
  opacity: 0.75;
  display: inline-block;
  margin-left: 8px;
}

.tag-input__text {
  border: none;
  outline: none;
  font-size: 1em;
  line-height: 40px;
  background: none;
}

.text-danger {
  color: #dc3545;
  font-size: 12px;
  margin-top: 4px;
  display: block;
}

.tag-input__tag.invalid {
  background-color: #dc3545;
}


select.form-select option {
  background: black;
  color: white;
}

/* For the default empty option */
select.form-select option[value=""] {
  background: black;
  color: white;
}

.legend-container {
  display: flex;
  gap: 20px;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.color-box {
  width: 16px;
  height: 16px;
  border-radius: 4px;
}

.legend-text {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
}

.tag-input {
  width: 100%;
  border: 1px solid rgba(250, 250, 250, 0.4);
  background: transparent;
  border-radius: 10px;
  font-size: 0.9em;
  min-height: 50px;
  /* Keep this as minimum height */
  height: auto !important;
  /* Force dynamic height */
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-content: flex-start;
}

.bi-check-circle-fill {
  font-size: 14px;
  color: white;
}

.bi-question-circle-fill {
  font-size: 14px;
  color: white;
}


.tag-input__text {
  flex: 1;
  min-width: 120px;
  height: 30px;
  border: none;
  outline: none;
  font-size: 1em;
  background: none;
  color: white;
  padding: 5px 0;
}

.tag-input__tag {
  display: inline-flex;
  align-items: center;
  height: 30px;
  color: white;
  font-size: 14px;
  background-color: #2980b9;
  border-radius: 15px;
  padding: 0 8px;
  margin: 2px;
}

input[type="text"] {
  background-color: transparent !important;
  color: white;
  border: 1px solid rgba(250, 250, 250, 0.4);
}

input[type="text"]:focus {
  background-color: transparent !important;
  color: white;
  box-shadow: none;
}

input[type="text"]::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.eska-tag-logo {
  width: 16px;
  height: 16px;
  object-fit: contain;
  vertical-align: middle;
  border-radius: 50%;
}
</style>
