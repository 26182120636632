<template>

  <div v-if="loading" class="text-center mt-5">
    <Spinner class="text-center mt-8 mb-8" />
  </div>




  <div class="main" v-else>

    <Nobankdoc v-if="Number(status_code) <= 104" class="mt-5" />

    <div v-else class="main-transaction">

      {{ school_id }}

      <div class="container-fluid">





        <div class="modal fade" id="dateFilterModal" tabindex="-1" aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="card w-60 mx-auto">
                <div class="modal-header" style="border-bottom:1px solid rgba(255, 255, 255, 0.4);">
                  <h6 class="modal-title" id="exampleModalLabel">{{ $t("transaction.modal_title") }}</h6>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <form @submit.prevent="applyDateFilter">
                  <div class="modal-body">
                    <div class="mb-3">
                      <label for="startDate" class="form-label">{{ $t("transaction.start_date") }}<em
                          style="color: #f60000">*</em></label>
                      <input style="background: transparent; color:white; border: 1px solid rgba(250,250, 250, 0.4);"
                        type="date" v-model="filterStartDate" class="form-control" id="startDate" required>
                    </div>
                    <div class="mb-3">
                      <label for="endDate" class="form-label">{{ $t("transaction.end_date") }} <em
                          style="color: #f60000">*</em></label>
                      <input style="background: transparent; color:white; border: 1px solid rgba(250,250, 250, 0.4);"
                        type="date" v-model="filterEndDate" class="form-control" id="endDate" required>
                    </div>
                  </div>
                  <div class="modal-footer" style="border-top:1px solid rgba(255, 255, 255, 0.4);">
                    <button style="border:1px solid rgba(255, 255, 255, 0.4); color:white;" type="button"
                      class="btn bg-transparent" data-bs-dismiss="modal"> {{ $t("button.close") }} </button>
                    <button data-bs-dismiss="modal" type="submit" class="btn btn-success">{{
                      $t("transaction.filter") }}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>






        <!--==================Alert==================-->

        <div class="card-api mt-4 mb-3 px-3"
          style="border: 1px solid rgba(250, 250, 250, 0.5); border-radius: 5px; background: #111c44;">
          <div class="card-api-content d-flex justify-content-between">
            <div class="d-flex mt-3 pt-1">
              <div class="key pe-2 me-3">
                <img style="height: 80px;" class="ms-2 me-1 rounded-circle" :src="this.bank_logo" alt="">
              </div>
              <div class="text mx-3">
                <h6 style="font-size: 14px;"> <span class="info_bank" v-if="detail && detail.data"> {{ this.bank_slug }}
                  </span> <span class="ms-1"> : {{ formatAccountNumber(transaction_number) }}</span> </h6>
                <p style="font-size: 14px;"> {{ $t("transfer.info_p") }} XAF {{
                  Number(this.transfer_min_amount).toLocaleString() }} - <router-link to="/Parem#payment-settings"
                    @click="handleParemNavigation" style="color:#60a5fa !important;">{{ $t("button.modify")
                    }}</router-link></p>
                <h6 style="font-size: 14px; margin-top: -10px;"> {{ $t("transfer.info_p2") }} </h6>
              </div>
            </div>
          </div>
        </div>


        <!--================== Table ==================-->

        <div style="margin-top: 100px;">
          <label for="" style="transform: translateY(-30px)"> {{ $t("transfer.filter") }}</label>
          <div class="dropdown" id="dropdown-status">
            <button class="btn dropdown-toggle text-light fw-bold ps-0" type="button" data-bs-toggle="dropdown"
              style="transform: translateY(-30px)">
              {{ selectedPeriodLabel }}
            </button>
            <ul class="dropdown-menu">
              <li @click="setPeriodFilter('all', $t('transfer.all_periods'))">
                {{ $t("transfer.all_periods") }}
              </li>
              <li @click="setPeriodFilter('today', $t('transfer.today'))">
                {{ $t("transfer.today") }}
              </li>
              <li @click="setPeriodFilter('yesterday', $t('transfer.yesterday'))">
                {{ $t("transfer.yesterday") }}
              </li>
              <li @click="setPeriodFilter('lastSevenDays', $t('transfer.since_the_seven_days'))">
                {{ $t("transfer.since_the_seven_days") }}
              </li>
              <li @click="setPeriodFilter('lastTwentyEightDays', $t('transfer.last_28_days'))">
                {{ $t("transfer.last_28_days") }}
              </li>
              <li data-bs-toggle="modal" data-bs-target="#dateFilterModal"
                @click="setPeriodFilter('custom', $t('transaction.filter_date'))">
                {{ $t("transaction.filter_date") }}
              </li>
            </ul>
          </div>
        </div>


        <div class="card px-0 mt-2">
          <div class="card-body px-0">
            <div class="row">
              <div class="col-md-6 px-0"></div>
              <div class="col-md-6 text-end" style="padding-right: 30px">

              </div>
            </div>

            <spinner class="text-center mt-8 mb-8" v-if="loading" style="height: 200px">
              <div>
                <span class="mt-4">
                  <Spinner></Spinner>
                </span>
              </div>
            </spinner>

            <table class="table px-0 mt-6 mb-6" v-else-if="transfers.length > 0">
              <thead>
                <tr style="border-bottom: 1px solid rgba(255, 255, 255, 0.1)">

                  <th class="border-0">
                    operation id
                  </th>



                  <th class=" border-0 text-start px-0">
                    {{ $t("transaction.start_date") }}
                  </th>

                  <th class=" border-0 text-start px-0">
                    {{ $t("transaction.end_date") }}
                  </th>

                  <th class=" border-0 text-start px-0">
                    {{ $t("transaction.status") }}
                  </th>

                  <th class=" border-0 text-end" style="text-decoration: none">
                    {{ $t("transfer.amount") }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="list in transfers" :key="list.id" style="
                             position: relative;
                             border-bottom: 1px solid rgba(255, 255, 255, 0.1);

              " class="transfer-row">



                  <td class="px-4 border-0 position-relative">
                    <span v-if="list.operation_id == null">
                      Aucune valeur
                    </span>
                    <span v-else>
                      {{ list.operation_id }}
                    </span>

                  </td>



                  <td class=" ps-0 pe-7 border-0 position-relative">

                    {{ formatDate(list.collected_from) }}

                  </td>

                  <td class=" ps-0 pe-7 border-0 position-relative">

                    {{ formatDate(list.collected_until) }}


                  </td>

                  <td class=" ps-0 pe-7 border-0 position-relative d-flex" v-if="list.status == 303">
                    <span class="status-code bg-gradient-success mx-1"> </span> {{ $t("transfer.executed") }}
                  </td>

                  <td class=" ps-0 pe-7 border-0 position-relative d-flex" v-if="list.status == 302">
                    <span class="status-code bg-warning mx-1"> </span> {{ $t("transfer.processing") }}
                  </td>

                  <td class=" ps-0 pe-7 border-0 position-relative d-flex" v-if="list.status == 301">
                    <span class="status-code  mx-1"> </span> {{ $t("transfer.ready") }}
                  </td>

                  <td class=" text-end  px-4 border-0 position-relative ">
                    <span class="text-filter">
                      XAF {{ Number(list.amount).toLocaleString() }}</span>
                    <button class="reclamation-btn"> {{ $t("transfer.reclamation") }} </button>
                  </td>


                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <!--================== NO transaction ==================-->
            <div class="else text-center pb-5" v-else>
              <div class="container">
                <div class="text mt-3">
                  <img class="image" src="@/assets/img/transaction.png" alt="" />
                  <h5 class="mt-4"> {{ $t("transfer.no_transfer") }} </h5>

                  <!---
                                <p class="mt-4 px-4">
                                    Utque proeliorum periti rectores primo catervas densas opponunt et fortes,
                                    deinde leves armaturas, post iaculatores
                                    ultimasque subsidiales acies, si fors adegerit, iuvaturas, ita praepositis urbanae.
                                </p>
                        -->

                </div>
              </div>
            </div>


          </div>
        </div>
      </div>




    </div>
  </div>



</template>

<script>
import Spinner from "../components/Spinner.vue";

import { getTransfers, getxpconnection, xpayconnection } from "@/utils/services";
import Nobankdoc from "@/views/components/Nobankdocs.vue";




function getFormattedDate() {
  const today = new Date();


  const start_date = "2020-01-01 00:00:00";

  // Current date with full precision
  const endYear = today.getFullYear();
  const endMonth = String(today.getMonth() + 1).padStart(2, '0');
  const endDay = String(today.getDate()).padStart(2, '0');
  const end_date = `${endYear}-${endMonth}-${endDay} 23:59:59`;

  return { start_date, end_date };
}


/**
function getFormattedDate() {
  const start_date = "2024-08-03 00:00:00";
  const end_date = "2024-09-03 00:00:00";
  return { start_date, end_date };
}
*/


export default {
  name: "historique-Transaction",
  components: { Spinner, Nobankdoc },
  data() {
    return {
      loading: false,
      transactionSummary: {
        total: { count: 0, amount: 0 },
        successful: { count: 0, amount: 0 },
        running: { count: 0, amount: 0 },
        failed: { count: 0, amount: 0 },
      },
      schoolId: '',
      xpay_connection_id: '',
      bank_slug: '',
      transaction_number: '',
      transfer_min_amount: '',
      bank_logo: '',
      transfers: [],
      detail: [],
      filterStartDate: '',
      filterEndDate: '',
      selectedPeriodLabel: this.$t("transfer.all_periods"),
      selectedPeriod: null,
      status_code: '',

    };
  },





  methods: {

    handleParemNavigation() {
      // Utiliser le router pour naviguer et ensuite scroller
      this.$router.push('/Parem').then(() => {
        // Attendre que la navigation soit terminée
        setTimeout(() => {
          const element = document.getElementById('payment-settings');
          if (element) {
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            });
          }
        }, 500); // Augmenter le délai pour s'assurer que la page est complètement chargée
      });
    },
    getFormattedDate,
    setPeriodFilter(period, label) {
      this.selectedPeriod = period;
      this.selectedPeriodLabel = label;

      // Call the corresponding filter method based on the selected period
      switch (period) {
        case 'all':
          this.fetchTransfers();
          break;
        case 'today':
          this.setFilterToday();
          break;
        case 'yesterday':
          this.setFilterYesterday();
          break;
        case 'lastSevenDays':
          this.setFilterLastSevenDays();
          break;
        case 'lastTwentyEightDays':
          this.setFilterLastTwentyEightDays();
          break;
        case 'custom':
          // For custom date, the modal will handle the filter
          break;
      }
    },



    setFilterToday() {
      const today = new Date();
      this.filterStartDate = this.formatDateToSQL(today);
      this.filterEndDate = this.formatDateToSQL(today);
      this.applyDateFilter();
    },

    setFilterYesterday() {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      this.filterStartDate = this.formatDateToSQL(yesterday);
      this.filterEndDate = this.formatDateToSQL(yesterday);
      this.applyDateFilter();
    },

    setFilterLastSevenDays() {
      const today = new Date();
      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(today.getDate() - 7);
      this.filterStartDate = this.formatDateToSQL(sevenDaysAgo);
      this.filterEndDate = this.formatDateToSQL(today);
      this.applyDateFilter();
    },

    setFilterLastTwentyEightDays() {
      const today = new Date();
      const twentyEightDaysAgo = new Date(today);
      twentyEightDaysAgo.setDate(today.getDate() - 28);
      this.filterStartDate = this.formatDateToSQL(twentyEightDaysAgo);
      this.filterEndDate = this.formatDateToSQL(today);
      this.applyDateFilter();
    },



    // Formater la date
    formatDate(dateString) {
      if (!dateString) return "N/A"; // Handle undefined or null dates
      const [date] = dateString.split('T');
      return date || "Invalid Date"; // Return a fallback for invalid dates
    },


    formatAccountNumber(number) {
      if (!number) return '';
      return number.replace(/(\d{5})(\d{5})(\d{11})(\d{2})/, '$1-$2-$3-$4');
    },





    async fetchxpayconnection() {
      this.loading = true;
      const sf_school_id = this.schoolId;


      try {
        this.loading = true;
        let response = await xpayconnection(sf_school_id);


        if (response && response.length > 0) {
          const connection = response[0];
          this.xpay_connection_id = connection.id;
          this.bank_slug = connection.bank_name;
          this.transfer_min_amount = connection.transfer_min_amount;
          this.transaction_number = connection.school_account_number;

          this.bank_logo = connection.bank_logo
          this.status_code = connection.status_code;

          localStorage.setItem('xpay_connection_id', this.xpay_connection_id);

          if (this.xpay_connection_id) {
            await this.fetchTransfers();
            await this.fetchXpconnection();
          }

        } else {
          console.error("Invalid response data or empty array:", response);
        }
      } catch (error) {
        console.error("Error during getconnection call:", error);
      } finally {
        this.loading = false;
      }
    },






    async fetchTransfers() {
      const xpay_connection_id = this.xpay_connection_id;
      const { start_date, end_date } = this.getFormattedDate();
      this.loading = true;

      try {
        let response = await getTransfers(xpay_connection_id, start_date, end_date);
        this.transfers = response || [];
        this.transactionSummary = response.summary || {
          total: { count: 0, amount: 0 },
          successful: { count: 0, amount: 0 },
          running: { count: 0, amount: 0 },
          failed: { count: 0, amount: 0 },
        };
      } catch (error) {
        console.error("Error fetching transfers:", error);
      } finally {
        this.loading = false;
      }
    },


    async fetchXpconnection() {

      const connection_id = this.xpay_connection_id;


      try {
        let response = await getxpconnection(connection_id);
        this.detail = response || [];

      } catch (error) {
        console.error("Error fetching xpconnection detail:", error);
      } finally {
        this.loading = false;
      }
    },

    updateOnlineStatus() {
      this.isOffline = !navigator.onLine;
      this.checkInternetConnection();
    },

    // Fonction pour formater la date dans le format "YYYY-MM-DD 00:00:00"
    formatDateToSQL(date) {
      if (!date) return '';
      const selectedDate = new Date(date);
      const year = selectedDate.getFullYear();
      const month = String(selectedDate.getMonth() + 1).padStart(2, '0'); // Ajout du 0 devant le mois si nécessaire
      const day = String(selectedDate.getDate()).padStart(2, '0'); // Ajout du 0 devant le jour si nécessaire
      return `${year}-${month}-${day} 00:00:00`; // Format final
    },

    // Méthode pour appliquer le filtre de date
    async applyDateFilter() {
      if (!this.filterStartDate || !this.filterEndDate) {
        alert("Veuillez sélectionner les deux dates");
        return;
      }

      // Formater les dates pour les envoyer dans le bon format
      const start_date = this.formatDateToSQL(this.filterStartDate);
      const end_date = this.formatDateToSQL(this.filterEndDate);

      // Appel à l'API pour récupérer les transferts filtrés
      try {
        const response = await getTransfers(this.xpay_connection_id, start_date, end_date);
        this.transfers = response || [];

      } catch (error) {
        console.error("Erreur lors de la récupération des transferts :", error);
      }
    },

  },



  mounted() {
    this.schoolId = localStorage.getItem("schoolId");
    this.fetchxpayconnection();
    this.fetchTransfers();
  },
};
</script>

<style>
.info_bank img {
  height: 30px;
  width: 30px;

}

#form {
  background: none;
  border: 1px solid rgba(250, 250, 250, 0.6);
  height: 45px;
}

#dropdown2 .btn {
  height: 45px;
  border: 1px solid rgba(250, 250, 250, 0.6);
  width: 200px;

}

#dropdown2 .dropdown-menu li {
  padding: 7px 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: 0.3s;
  color: white;
}

#dropdown2 .dropdown-menu li:hover {
  background: rgba(255, 255, 255, 0.1);
}




#card .card1 {
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
  color: white;
  border-radius: 10px;
}

#card .card1 span {
  font-size: 18px;
}

#card .card1 .price {
  font-size: 25px;
  font-weight: bold;
}

.table tbody tr td {
  padding-top: 13px;
  padding-bottom: 13px;
}

#span-table {
  width: 150px;
}



.transfer-row {
  position: relative;
  transition: 3s;
}

.reclamation-btn {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
  background-color: #4f61c4;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.transfer-row:hover {
  background: rgba(255, 255, 255, 0.1);
}

.transfer-row:hover .text-filter {

  filter: blur(5px);
}

.transfer-row:hover .reclamation-btn {
  display: block;

}

.status-code {

  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: #60a5fa;
  content: "";
  display: block;
  transform: translateY(5px);
}
</style>