<template>

    <div>
 


        <div class="main-transaction">
            <!-- Rest of your existing content -->

            <!-- La modale pour sélectionner les dates -->
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="card w-60 mx-auto">
                            <div class="modal-header" style="border-bottom:1px solid rgba(255, 255, 255, 0.4);">
                                <h6 class="modal-title" id="exampleModalLabel">{{ $t("transaction.modal_title") }}</h6>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                            </div>
                            <form @submit.prevent="applyDateFilter">
                                <div class="modal-body">
                                    <div class="mb-3">
                                        <label for="startDate" class="form-label">{{ $t("transaction.start_date")
                                            }} <em style="color: #f60000">*</em></label>
                                        <input
                                            style="background: transparent; color:white; border: 1px solid rgba(250,250, 250, 0.4);"
                                            type="date" v-model="filterStartDate" class="form-control" id="startDate"
                                            required>
                                    </div>
                                    <div class="mb-3">
                                        <label for="endDate" class="form-label">{{ $t("transaction.end_date") }} <em
                                                style="color: #f60000">*</em></label>
                                        <input
                                            style="background: transparent; color:white; border: 1px solid rgba(250,250, 250, 0.4);"
                                            type="date" v-model="filterEndDate" class="form-control" id="endDate"
                                            required>
                                    </div>
                                </div>
                                <div class="modal-footer" style="border-top:1px solid rgba(255, 255, 255, 0.4);">
                                    <button style="border:1px solid rgba(255, 255, 255, 0.4); color:white;"
                                        type="button" class="btn bg-transparent" data-bs-dismiss="modal"> {{
                                            $t("button.close") }}
                                    </button>
                                    <button data-bs-dismiss="modal" type="submit" class="btn btn-success">{{
                                        $t("transaction.filter") }}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Le reste de ton template (tableau de transactions, etc.) -->
            <!-- ... -->






            <div class="container-fluid">
                <div class="d-flex" style="margin-top: 0px;">
                    <div class=" " style="margin-top: 30px;">
                        <label for="" style="transform: translateY(-30px)"> {{ $t("transaction.operator") }} </label>
                        <div class="dropdown" id="dropdown-status">
                            <button class="btn dropdown-toggle ps-0 text-light fw-bold" type="button"
                                data-bs-toggle="dropdown" style="transform: translateY(-30px)">
                                <input class="bg-transparent text-light border-0 fw-bold ps-2" type="text"
                                    v-model="selectedOperatorDisplay" readonly>
                            </button>
                            <ul class="dropdown-menu">
                                <li v-for="operator in operators" :key="operator.value"
                                    @click="handleOperatorSelect(operator)">
                                    {{ operator.label }}
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div class="mx-4" style="margin-top: 30px;">
                        <label for="" style="transform: translateY(-30px)"> {{ $t("transaction.status") }} </label>
                        <div class="dropdown" id="dropdown-status">
                            <button class="btn dropdown-toggle text-light fw-bold ps-0" type="button"
                                data-bs-toggle="dropdown" style="transform: translateY(-30px)">
                                <input class="bg-transparent text-light border-0 fw-bold ps-2" type="text"
                                    v-model="selectedStatusDisplay" readonly>
                            </button>
                            <ul class="dropdown-menu">
                                <li v-for="statusItem in statuses" :key="statusItem.value"
                                    @click="handleStatusSelect(statusItem)">
                                    {{ statusItem.label }}
                                </li>
                            </ul>
                        </div>

                    </div>


                    <div style="margin-top: 30px;">
                        <label for="" style="transform: translateY(-30px)">{{ $t("transfer.filter") }}</label>
                        <div class="dropdown" id="dropdown-status">
                            <button class="btn dropdown-toggle text-light fw-bold ps-0" type="button"
                                data-bs-toggle="dropdown" style="transform: translateY(-30px)">
                                {{ selectedPeriod || $t("transfer.all_periods") }}
                            </button>

                            <ul class="dropdown-menu">
                                <li v-for="period in periods" :key="period.value" @click="handlePeriodSelect(period)"
                                    :class="{ active: selectedPeriod === period.label }">
                                    {{ period.label }}
                                </li>
                                <li data-bs-toggle="modal" data-bs-target="#exampleModal">
                                    {{ $t("transaction.filter_date") }}
                                </li>
                            </ul>
                        </div>
                    </div>



                </div>
            </div>

            <!--Card -box -->
            <div class="container-fluid mt-1">
                <div class="row " id="card">


                    <div class="col-md-3">
                        <div class="card1 bg-gradient-success">
                            <div class="top">
                                <span> {{ $t("transaction.successful") }} : {{ transactionSummary.successful.count
                                    }}</span>
                            </div>
                            <div class="botoom text-end mt-3">
                                <spinner class="text-center " v-if="loading">
                                    <div>
                                        <span class="mt-4">
                                            <Spinner></Spinner>
                                        </span>
                                    </div>
                                </spinner>

                                <span class="price" v-else-if="transactionSummary"> <span
                                        style="font-size:16px;opacity:0.8;">XAF </span>{{
                                            Number(transactionSummary.successful.amount).toLocaleString() }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="card1 bg-gradient-warning">
                            <div class="top">
                                <span> {{ $t("transaction.running") }}: {{ transactionSummary.running.count }}</span>
                            </div>
                            <div class="botoom text-end mt-3">
                                <spinner class="text-center " v-if="loading">
                                    <div>
                                        <span class="mt-4">
                                            <Spinner></Spinner>
                                        </span>
                                    </div>
                                </spinner>

                                <span class="price" v-else-if="transactionSummary"> <span
                                        style="font-size:16px;opacity:0.8;">XAF </span> {{
                                            Number(transactionSummary.running.amount).toLocaleString() }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="card1 bg-gradient-danger">
                            <div class="top">
                                <span> {{ $t("transaction.failed") }}: {{ transactionSummary.failed.count }}</span>
                            </div>
                            <div class="botoom text-end mt-3">
                                <spinner class="text-center " v-if="loading">
                                    <div>
                                        <span class="mt-4">
                                            <Spinner></Spinner>
                                        </span>
                                    </div>
                                </spinner>

                                <span class="price" v-else-if="transactionSummary"> <span
                                        style="font-size:16px;opacity:0.8;">XAF </span> {{
                                            Number(transactionSummary.failed.amount).toLocaleString() }}
                                </span>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <div class="container-fluid">
                <div class="card px-0 mt-5">
                    <div class="card-body px-0">

                        <spinner class="text-center mt-8 mb-8" v-if="loading" style="height: 200px">
                            <div>
                                <span class="mt-4">
                                    <Spinner></Spinner>
                                </span>
                            </div>
                        </spinner>


                        <table class="table px-0 mt-6 mb-6" v-else-if="filteredTable.length > 0">
                            <thead>
                                <tr style="border-bottom: 1px solid rgba(255, 255, 255, 0.1)">
                                    <th scope="col" class="border-0 w-20 ">
                                        {{ $t("transaction.operator") }}
                                    </th>
                                    <th class="border-0 text-center w-20 pe-0" style="text-decoration: none">
                                        {{ $t("transaction.amount") }} (XAF)
                                    </th>
                                    <th class="border-0 w-20 text-center pe-0" style="text-decoration: none">
                                        Id Transaction
                                    </th>
                                    <th class="border-0 w-20 text-center pe-0">
                                        Id compte
                                    </th>
                                    <th class="border-0 w-20 text-center pe-0">
                                        {{ $t("transaction.date") }}
                                    </th>
                                    <th class="text-end w-20 border-0">
                                        {{ $t("transaction.status") }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="list in paginatedfilteredTable" :key="list.id"
                                    style="position: relative; border-bottom: 1px solid rgba(255, 255, 255, 0.1);">
                                    <td scope="row" class="px-4 border-0">
                                        {{ list.wallet }}
                                    </td>

                                    <td class="border-0  pe-5 text-end">
                                        <span>{{ Number(list.amount - list.fees).toLocaleString() }}</span>
                                    </td>

                                    <td class="border-0  text-end pe-5 ">

                                        <span class=""> {{ list.processor_tx_id }}</span>
                                    </td>

                                    <td class="text-end border-0  position-relative pe-6 ">
                                        {{ list.account_id }}
                                    </td>
                                    <td class="ps-0 text-end border-0 pe-6 position-relative">
                                        {{ formatDate(list.processed_on) }}
                                    </td>
                                    <td class="text-end  border-0 position-relative pe-4">
                                        <div v-if="list.status === 'FAILED'" class="  text-end" style="width: 130px">
                                            {{ $t("transaction.failed") }}
                                        </div>
                                        <div v-if="list.status === 'APIError'" title="Erreur d'API" class="   text-end"
                                            style="width: 130px">
                                            Erreur
                                        </div>
                                        <div v-if="list.status === 'Success'" class=" text-end" style="width: 130px">
                                            {{ $t("transaction.successful") }}
                                        </div>
                                        <div v-if="list.status === 'Pending'" class="  text-end" style="width: 130px">
                                            {{ $t("transaction.running") }}
                                        </div>
                                        <div v-if="list.status === 'Dropped'" class=" text-end" style="width: 130px">
                                            Abandonnées
                                        </div>
                                        <div v-if="list.status === 'SimulatedFail'" class=" text-end"
                                            style="width: 130px">
                                            Simulation d'echec
                                        </div>
                                        <div v-if="list.status === 'Declined'" class="text-end" style="width: 130px">
                                            Refusées
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>


                        </table>


                        <div class="w-100  justify-content-center text-center" v-else
                            style="height: auto; padding: 70px 0;">
                            <div>
                                <img style="width:300px" class="image" src="@/assets/img/transaction.png" alt="" />
                                <h5 style="transform: translateX(40px)" class="mt-4">{{ $t("transaction.no_transaction")
                                    }}
                                </h5>

                            </div>
                            <p style="width:max-content" class="mx-auto text-center">
                                {{ $t("transaction.no_transaction_text") }} </p>

                        </div>

                        <div class="">
                            <nav aria-label="..." v-if="filteredTable.length > 0">
                                <ul class="pagination">
                                    <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                        <button class="page-link" @click="prevPage" :disabled="currentPage === 1"
                                            style="background: none; color: white">
                                            <i class="bi bi-chevron-left"></i>
                                        </button>
                                    </li>
                                    <li v-for="page in totalPages" :key="page" class="page-item"
                                        :class="{ active: currentPage === page }">
                                        <button class="page-link fw-bold" style="background: none"
                                            @click="() => setCurrentPage(page)">
                                            {{ page }}
                                        </button>
                                    </li>

                                    <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                        <button class="page-link" @click="nextPage"
                                            :disabled="currentPage === totalPages" style=" background: none;  color: white;">
                                            <i class="bi bi-chevron-right"></i>
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { getTransactions, xpayconnection } from "@/utils/services";
import Spinner from "../components/Spinner.vue";





// Fonction pour formater les dates pour la requête des transactions

function getFormattedDate() {
    const today = new Date();

    // Soustraire 2 jours de la date actuelle
    const twoDaysAgo = new Date(today);
    twoDaysAgo.setDate(today.getDate() - 2);

    // Formater la date au format YYYY-MM-DD pour 'starting_on'
    const year = twoDaysAgo.getFullYear();
    const month = String(twoDaysAgo.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
    const day = String(twoDaysAgo.getDate()).padStart(2, '0');

    const starting_on = `${year}-${month}-${day}`;

    // Formater la date pour 'ending_on' (aujourd'hui)
    const endYear = today.getFullYear();
    const endMonth = String(today.getMonth() + 1).padStart(2, '0');
    const endDay = String(today.getDate()).padStart(2, '0');

    const ending_on = `${endYear}-${endMonth}-${endDay}`;

    return { starting_on, ending_on };
}

/**
function getFormattedDate() {
    const starting_on = "2024-06-01";
    const ending_on = "2024-09-01";
    return { starting_on, ending_on };
}
 */

export default {
    name: "historique-Transaction",
    components: { Spinner, },
    data() {
        return {
            isOffline: !navigator.onLine,
            searchName: "",
            searchStatus: "",
            no: "",
            schoolId: '',
            schoolInfo: {},
            xpay_connection_id: '',
            period: 'today',
            status: '',
            wallet: '',
            selectedOperator: '',
            selectedOperatorText: 'Tous les opérateurs',
            selectedStatus: '',
            selectedStatusText: this.$t("transaction.all_status"),
            selectedPeriodText: this.$t("transaction.today"),
            table: [], // Liste des transactions
            transactionSummary: {
                total: { count: 0, amount: 0 },
                successful: { count: 0, amount: 0 },
                running: { count: 0, amount: 0 },
                failed: { count: 0, amount: 0 },
            },
            loading: true,
            selectedOperatorDisplay: this.$t("transaction.all_operator"),
            selectedStatusDisplay: this.$t("transaction.all_status"),

            currentPage: 1,
            itemsPerPage: 50,
            startDate: '',
            endDate: '',
            maxDate: '',
            minDate: '',
            filterStartDate: '',
            filterEndDate: '',
            selectedPeriod: '',
            periods: [
                { value: 'all', label: this.$t("transfer.all_periods") },
                { value: 'today', label: this.$t("transfer.today") },
                { value: 'yesterday', label: this.$t("transfer.yesterday") },
                { value: 'last7days', label: this.$t("transfer.since_the_seven_days") },
                { value: 'last28days', label: this.$t("transfer.last_28_days") }
            ],
            operators: [
                { value: '', label: this.$t("transaction.all_operator") },
                { value: 'orange-money', label: 'Orange-money' },
                { value: 'mtn-momo', label: 'Mtn-momo' },
                { value: 'bicec', label: 'Bicec' },
                { value: 'sara', label: 'Sara' },
                { value: 'eumm', label: 'Express-union' },
                { value: 'me2u', label: 'Me2u' }
            ],
            statuses: [
                { value: '', label: this.$t("transaction.all_status") },
                { value: 'Success', label: this.$t("transaction.successful") },
                { value: 'Pending', label: this.$t("transaction.running") },
                { value: 'FAILED', label: this.$t("transaction.failed") }
            ],
            status_code: '',
        };
    },
    computed: {
        // Filtrer la table des transactions selon les critères de recherche
        filteredTable() {
            return this.table.filter((transaction) => {
                const matchesName = transaction.wallet.toLowerCase().includes(this.searchName.toLowerCase());
                const matchesStatus = this.searchStatus ? transaction.status === this.searchStatus : true;
                const matchesOperator = this.selectedOperator ? transaction.wallet === this.selectedOperator : true;
                return matchesName && matchesStatus && matchesOperator;
            });
        },

        // Calculer le montant total des transactions filtrées
        totalAmount() {
            return this.filteredTable.reduce(
                (total, item) => total + parseFloat(item.amount || 0),
                0
            );
        },

        // Calculer le nombre total de pages pour la pagination
        totalPages() {
            return Math.ceil(this.filteredTable.length / this.itemsPerPage);
        },

        // Pagination des transactions filtrées
        paginatedfilteredTable() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            return this.filteredTable.slice(startIndex, endIndex);
        },
    },
    methods: {

        async fetchxpayconnection() {

            const sf_school_id = this.schoolId;


            try {
                this.loading = true;
                let response = await xpayconnection(sf_school_id);


                if (response && response.length > 0) {
                    const connection = response[0];
                    this.status_code = connection.status_code;
                    this.xpay_connection_id = connection.id;

                } else {
                    console.error("Invalid response data or empty array:", response);
                }
            } catch (error) {
                console.error("Error during getconnection call:", error);
            } finally {
                this.loading = false;
            }
        },


        handleOperatorSelect(operator) {
            this.wallet = operator.value;
            this.selectedOperatorDisplay = operator.label;
            this.fetchTransactions();
        },

        handleStatusSelect(statusItem) {
            this.status = statusItem.value;
            this.selectedStatusDisplay = statusItem.label;
            this.fetchTransactions();
        },

        handlePeriodSelect(period) {
            this.selectedPeriod = period.label;

            switch (period.value) {
                case 'all':
                    this.filterStartDate = '';
                    this.filterEndDate = '';
                    this.fetchTransactions();
                    break;
                case 'today':
                    this.setFilterToday();
                    break;
                case 'yesterday':
                    this.setFilterYesterday();
                    break;
                case 'last7days':
                    this.setFilterLastSevenDays();
                    break;
                case 'last28days':
                    this.setFilterLastTwentyEightDays();
                    break;
            }
        },


        formatDateToSQL(date) {
            if (!date) return '';
            const selectedDate = new Date(date);
            const year = selectedDate.getFullYear();
            const month = String(selectedDate.getMonth() + 1).padStart(2, '0'); // Ajout du 0 devant le mois si nécessaire
            const day = String(selectedDate.getDate()).padStart(2, '0'); // Ajout du 0 devant le jour si nécessaire
            return `${year}-${month}-${day} `; // Format final
        },

        setFilterToday() {
            const today = new Date();
            this.filterStartDate = this.formatDateToSQL(today);
            this.filterEndDate = this.formatDateToSQL(today);
            this.applyDateFilter();
        },

        setFilterYesterday() {
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            this.filterStartDate = this.formatDateToSQL(yesterday);
            this.filterEndDate = this.formatDateToSQL(yesterday);
            this.applyDateFilter();
        },

        setFilterLastSevenDays() {
            const today = new Date();
            const sevenDaysAgo = new Date(today);
            sevenDaysAgo.setDate(today.getDate() - 7);
            this.filterStartDate = this.formatDateToSQL(sevenDaysAgo);
            this.filterEndDate = this.formatDateToSQL(today);
            this.applyDateFilter();
        },

        setFilterLastTwentyEightDays() {
            const today = new Date();
            const twentyEightDaysAgo = new Date(today);
            twentyEightDaysAgo.setDate(today.getDate() - 28);
            this.filterStartDate = this.formatDateToSQL(twentyEightDaysAgo);
            this.filterEndDate = this.formatDateToSQL(today);
            this.applyDateFilter();
        },




        setWalletAndFetchTransactions() {
            this.wallet = '';
            this.fetchTransactions();
        },

        setWalletAndFetchTransactionsom() {
            this.wallet = 'orange-money';
            this.fetchTransactions();
        },

        setWalletAndFetchTransactionsmt() {
            this.wallet = 'mtn-momo';
            this.fetchTransactions();
        },


        setWalletAndFetchTransactionsbi() {
            this.wallet = 'bicec';
            this.fetchTransactions();
        },
        setWalletAndFetchTransactionssa() {
            this.wallet = 'sara';
            this.fetchTransactions();
        },
        setWalletAndFetchTransactionsex() {
            this.wallet = 'eumm';
            this.fetchTransactions();
        },

        setWalletAndFetchTransactionsme() {
            this.wallet = 'me2u';
            this.fetchTransactions();
        },

        setstatusAndFetchTransactions() {
            this.status = '';
            this.fetchTransactions();
        },
        setstatusAndFetchTransactionssuc() {
            this.status = 'Success';
            this.fetchTransactions();
        },

        setstatusAndFetchTransactionspen() {
            this.status = 'Pending';
            this.fetchTransactions();
        },

        setstatusAndFetchTransactionsfa() {
            this.status = 'FAILED';
            this.fetchTransactions();
        },



        // Utilisation de la fonction pour obtenir la date formatée
        getFormattedDate,

        // Gérer la pagination
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        setCurrentPage(page) {
            this.currentPage = page;
        },

        // Récupérer la connexion de l'école (si nécessaire)





        // Récupérer les transactions
        async fetchTransactions() {
            const xpay_connection_id = this.xpay_connection_id;
            const ending_on = new Date().toISOString().split('T')[0];
            const starting_on = "2020-01-01"
            const wallet = this.wallet;
            const status = this.status;

            this.loading = true;
            try {
                let response = await getTransactions(xpay_connection_id, starting_on, ending_on, wallet, status);
                if (response) {
                    this.table = response.transactions || [];
                    this.transactionSummary = response.summary || {
                        total: { count: 0, amount: 0 },
                        successful: { count: 0, amount: 0 },
                        running: { count: 0, amount: 0 },
                        failed: { count: 0, amount: 0 },
                    };
                } else {
                    console.error("La réponse est vide ou non définie.");
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des transactions :", error);
                this.failedMessage = "Une erreur s'est produite lors de la récupération des informations.";
            } finally {
                this.loading = false;
            }
        },





        async applyDateFilter() {
            if (!this.filterStartDate || !this.filterEndDate) {
                alert("Veuillez sélectionner les deux dates");
                return;
            }

            const xpay_connection_id = "this.xpay_connection_id";
            const wallet = this.wallet;
            const starting_on = this.filterStartDate.trim(); // Remove any extra spaces
            const ending_on = this.filterEndDate.trim();
            const status = this.status;

            this.loading = true;
            try {
                const response = await getTransactions(xpay_connection_id, starting_on, ending_on, wallet, status);
                if (response) {
                    this.table = response.transactions || [];
                    this.transactionSummary = response.summary || {
                        total: { count: 0, amount: 0 },
                        successful: { count: 0, amount: 0 },
                        running: { count: 0, amount: 0 },
                        failed: { count: 0, amount: 0 },
                    };
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des transferts :", error);
            } finally {
                this.loading = false;
            }
        },



        setOperator(operator, text) {
            this.selectedOperator = operator;
            this.selectedOperatorText = text;
        },

        setStatus(status, text) {
            this.searchStatus = status;
            this.selectedStatusText = text;
        },


        // Formater la date
        formatDate(dateString) {
            if (!dateString) return "N/A"; // Handle undefined or null dates
            const [date] = dateString.split('T');
            return date || "Invalid Date"; // Return a fallback for invalid dates
        },

    },
    async mounted() {
    this.schoolId = localStorage.getItem("schoolId");
    
    // Wait for connection to be fetched first
    await this.fetchxpayconnection();
    // Then fetch transactions with the obtained xpay_connection_id
    await this.fetchTransactions();

    const today = new Date();
    this.maxDate = today.toISOString().split('T')[0];
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 3);
    this.minDate = threeMonthsAgo.toISOString().split('T')[0];

    window.addEventListener('offline', () => {
        this.isOffline = true;
    });
    window.addEventListener('online', () => {
        this.isOffline = false;
    });
}

};
</script>




<style>
#form {
    background: none;
    border: 1px solid rgba(250, 250, 250, 0.6);
    height: 45px;
}

#dropdown2 .btn {
    height: 45px;
    border: 1px solid rgba(250, 250, 250, 0.6);
    width: 200px;

}

#dropdown2 .dropdown-menu li {
    padding: 7px 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    transition: 0.3s;
    color: white;
}

#dropdown2 .dropdown-menu li:hover {
    background: rgba(255, 255, 255, 0.1);
}

#dropdown-status .btn {
    height: 45px;
    border: 1px solid rgba(250, 250, 250, 0.6);
    width: 200px;
    background: none;
}

#dropdown-status .dropdown-menu {
    background-color: #293357;
}

#dropdown-status .dropdown-menu li {
    padding: 7px 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    transition: 0.3s;
    color: white;
    cursor: pointer;
}

#dropdown-status .dropdown-menu li:hover {
    background: rgba(255, 255, 255, 0.1);
}




#card .card1 {
    padding-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
    color: white;
    border-radius: 10px;
    height: 140px;
}

#card .card1 span {
    font-size: 18px;
}

#card .card1 .price {
    font-size: 25px;
    font-weight: bold;
}

.table tbody tr td {
    padding-top: 13px;
    padding-bottom: 13px;
}

#span-table {
    width: 150px;
}

#option1 {
    background-color: transparent;
    color: rgba(250, 250, 250, 0.6);
    border: 1px solid rgba(250, 250, 250, 0.6);
    height: 45px;

}

#option1[value="Declined"] {
    background-color: transparent;
    color: rgba(250, 250, 250, 0.6);
    border: 1px solid rgba(250, 250, 250, 0.6);
    height: 45px;
}

.pagination {
    transform: translateY(-50px);
    display: flex;
}

.pagination button {
    margin: 0 5px;
    cursor: pointer;
    background-color: #081739;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    margin-left: 20px;
}

.pagination button:hover {
    background-color: #0056b3;
}

.pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

#dropdown-status input {
    border: none;
    background: transparent;
    color: white;
    width: 100%;
    outline: none;
}

#dropdown-status input:focus {
    border: none;
    outline: none;
    box-shadow: none;
}
</style>
